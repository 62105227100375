import { Layout, useSignupContext } from "Components";
import { Button } from "melodies-source/Button";
import { Body1, H4 } from "melodies-source/Text";
import { ReactComponent as Logo } from "assets/svg/logo.svg";
import { styled } from "styled-components";

export const VerificationLinkSent = () => {
  const { handleStart, lastEmail } = useSignupContext();
  return (
    <Layout
      flow="login"
      cardProps={{
        actions: {
          buttons: (
            <Button
              variant="secondary"
              onClick={() => handleStart({ email: lastEmail })}
            >
              Resend
            </Button>
          ),
        },
      }}
    >
      <Container>
        <Logo />
        <H4>Verification email sent!</H4>
        <Body1>
          We’ve sent an email to {lastEmail} to verify your email and activate
          your account. The link in the email will expire in 24 hours.
        </Body1>
      </Container>
    </Layout>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  text-align: center;
  width: 100%;

  svg {
    width: 150px;
    height: 66px;
  }

  button {
    width: 100%;
  }
`;
