import { useEffect, useState } from "react";
import {
  Layout,
  firebaseApp,
  firestore,
  functions,
  useSignupContext,
} from "Components";
import { Body2, H2, H3 } from "melodies-source/Text";
import { TextInput } from "melodies-source/TextInput";
import { styled } from "styled-components";
import { Button } from "melodies-source/Button";
import { useDesktopMediaQuery } from "hooks/useDesktopMediaQuery";
import { ReactComponent as ArrowRightIcon } from "assets/svg/arrow-right.svg";
import { ReactComponent as KeyIcon } from "assets/svg/key.svg";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { useNavigate } from "react-router-dom";

const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[@$!%*#?&]).{8,}$/;

export const EnterPassword = () => {
  const { active } = useSignupContext();
  const auth = getAuth();
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordIsValid, setPasswordIsValid] = useState(true);
  const [passwordIsSame, setPasswordIsSame] = useState(true);
  const [loading, setLoading] = useState(false);
  const { handleNext } = useSignupContext();
  const isDesktop = useDesktopMediaQuery();
  const navigate = useNavigate();
  const sendPassword = httpsCallable<
    { action: string; password: string; signupId: string },
    { status: string; token: string }
  >(functions, "signup-account");

  const validatePassword = () => {
    let valid = true;
    if (!PASSWORD_REGEX.test(password)) {
      setPasswordIsValid(false);
      valid = false;
    }
    if (password !== passwordConfirm) {
      setPasswordIsSame(false);
      valid = false;
    }

    if (valid) {
      navigate("/setpassword"); // This line is used for tracking purposes and does not actually affect navigation
      setLoading(true);
      sendPassword({ action: "password", signupId: active!.id, password })
        .then((res) => {
          if (res.data.token) {
            return signInWithCustomToken(auth, res.data.token).then(() => {
              handleNext();
            });
          }
        })
        .catch((err) => console.error("password set failed", err))
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (!passwordIsValid) setPasswordIsValid(true);
    if (!passwordIsSame) setPasswordIsSame(true);
  }, [password]);

  useEffect(() => {
    if (!passwordIsSame) setPasswordIsSame(true);
  }, [passwordConfirm]);

  const Heading = isDesktop ? H2 : H3;

  return (
    <Layout>
      <Container>
        <Heading>Please create a password for your account.</Heading>
        <TextInput
          label="Password"
          value={password}
          onChange={setPassword}
          type="password"
          leftIcon={<KeyIcon />}
          {...(!passwordIsValid && {
            hasError: true,
            helperText: "Please enter a valid password",
          })}
        />
        <TextInput
          label="Confirm Password"
          value={passwordConfirm}
          onChange={setPasswordConfirm}
          type="password"
          leftIcon={<KeyIcon />}
          {...(!passwordIsSame && {
            hasError: true,
            helperText: "Passwords don't match",
          })}
        />
        <Body2>
          Password must be at least 8 characters, contain at least one special
          character, one letter, and one number
        </Body2>
        <NextButton
          disabled={loading || !password}
          onClick={validatePassword}
          rightIcon={<ArrowRightIcon />}
          loading={loading}
        >
          Next
        </NextButton>
      </Container>
    </Layout>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  ${H2}, ${H3},${Body2} {
    text-align: center;
  }

  ${Body2} {
    color: #333333;
  }
`;

const NextButton = styled(Button)`
  margin-top: 15px;
`;
