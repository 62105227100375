import React, { useEffect, useState } from "react";
import { usePopper } from "react-popper";
import styled from "styled-components";
import { useClickOutside } from "../utils/hooks";

export const SelectMenuBackdrop = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  margin: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  display: flex;
`;

export const SelectMenuDiv = styled.div<{ hasButton?: boolean }>`
  background-color: white;
  color: #333;
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  max-height: 250px;
  z-index: 10000000000;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.32);
  border: 1px solid rgb(249, 250, 251);
  position: absolute;
  border-radius: 4px;
  margin-top: 10px;

  &[data-popper-placement~="top"] {
    border-radius: 6px 6px 0 0;
  }
`;

type Props = {
  isOpen?: boolean;
  onClose?: Function;
  onClickOutside?: Function;
  children: any;
  style?: any;
};

const modifiers = [
  {
    name: "offset",
    options: {
      offset: ({ placement }: { placement: string }) => {
        if (placement === "top") {
          return [0, 40];
        } else {
          return [0, 0];
        }
      },
    },
  },
];

export const SelectMenu = ({
  isOpen = false,
  onClickOutside,
  onClose = () => {},
  children,
  ...props
}: Props) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: "bottom",
      modifiers,
    }
  );
  useClickOutside(referenceElement, onClickOutside ?? onClose);

  useEffect(() => {
    if (update) update();
  }, [isOpen]);
  return (
    <>
      {isOpen && (
        <div
          ref={setReferenceElement}
          style={{ position: "relative" }}
          {...props}
        >
          <SelectMenuDiv
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            {children}
          </SelectMenuDiv>
        </div>
      )}
    </>
  );
};
