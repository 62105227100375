import React, {
  MutableRefObject,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { ProgressRing } from "melodies-source/ProgressRing";
import { styled } from "styled-components";
import { ReactComponent as ImageIcon } from "assets/svg/image-2.svg";
import { Body1 } from "melodies-source/Text";
import { Button } from "melodies-source/Button";
import { useImageInputContext } from "melodies-source/ImageInput/ImageInputContextProvider";
import {
  UploadTask,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { useSignupContext } from "Components";

export const Uploading: React.FC = () => {
  const { active } = useSignupContext();
  const { onCloseModal, value, setImageUrl } = useImageInputContext();
  const [progress, setProgress] = useState(0);
  const uploadTaskRef = useRef<Nullable<UploadTask>>(null);

  const hanldeCancel = () => {
    uploadTaskRef.current?.cancel();
    onCloseModal();
    uploadTaskRef.current = null;
  };

  const uploadImage = () => {
    const storageRef = ref(
      getStorage(),
      `/signups/${active?.id}/${(Math.random() + 1).toString(36).substring(7)}`
    );

    if (!uploadTaskRef.current) {
      const uploadTask = uploadBytesResumable(storageRef, value!);
      uploadTaskRef.current = uploadTask;

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
        },
        (error) => {
          console.error(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setImageUrl(downloadURL);
          });
          setTimeout(() => {
            onCloseModal();
          }, 1000);
        }
      );
    }
  };

  useEffect(() => uploadImage(), []);

  return (
    <Container>
      <ProgressContainer>
        <StyledImageIcon />
        <ProgressRing progress={progress} />
      </ProgressContainer>
      <Body1>{progress.toFixed(0)}%</Body1>
      <Body1>Please wait...</Body1>
      <ButtonContainer>
        <StyledButton onClick={hanldeCancel}>Cancel</StyledButton>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledImageIcon = styled(ImageIcon)`
  position: absolute;
  z-index: 2;
  color: #dcdcdc;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 40px;
`;

const StyledButton = styled(Button)`
  ${({ theme }) => theme.media.mobile} {
    width: 100%;
  }
`;
