import {
  AsYouType,
  CountryCode,
  formatIncompletePhoneNumber,
  getCountryCallingCode,
  parseIncompletePhoneNumber,
  PhoneNumber,
} from "libphonenumber-js/mobile";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { SvgMobile } from "../Svgs/Mobile";
import { HTMLInput, TextInput as TextInputBase } from "../TextInput";
import { CountrySelect } from "./CountrySelect";

const TextInput = styled(TextInputBase)`
  ${HTMLInput} {
    border-radius: 0 6px 6px 0;
    box-shadow: unset !important;
    border: 0 none !important;
    border-left: 1px solid #ccc !important;
    line-height: 22px;
  }
`;

const useCountryPhone = (value: string, countryCode: CountryCode) => {
  const getPhoneNumber = (_value: string): PhoneNumber | undefined => {
    const asYouType = new AsYouType(countryCode as CountryCode);
    asYouType.input(_value);
    return asYouType?.getNumber();
  };

  const valueWithFormat = (() => {
    const phoneNumber = getPhoneNumber(value);
    return !!phoneNumber?.number
      ? phoneNumber?.format(countryCode === "US" ? "NATIONAL" : "INTERNATIONAL")
      : value;
  })();

  return {
    valueWithFormat,
    textInputProps: {
      value: formatIncompletePhoneNumber(valueWithFormat, countryCode),
      placeholder:
        countryCode === "US"
          ? "(000) 000-0000"
          : `+${getCountryCallingCode(countryCode)} 000000000000`,
    },
    getPhoneNumber,
  };
};

export type CountryPhoneState = {
  value: string;
  countryCode: CountryCode;
  countryCallingCode: string;
  isValid?: boolean;
};

type Props = {
  value: string;
  style?: any;
  onChange: (s: CountryPhoneState) => void;
} & Omit<React.BaseHTMLAttributes<HTMLDivElement>, "onChange">;

export const CountryPhone = ({ value = "", onChange, ...props }: Props) => {
  const [countryCode, setCountryCode] = useState<CountryCode>("US");
  const [focus, setFocus] = useState<boolean>(false);
  const countryCallingCode = getCountryCallingCode(countryCode) as string;

  const { valueWithFormat, textInputProps, getPhoneNumber } = useCountryPhone(
    value,
    countryCode
  );

  useEffect(() => {
    onChange({
      value: "",
      countryCode,
      countryCallingCode,
      isValid: false,
    });
  }, [countryCode]);

  useEffect(() => {
    const newCountryCode = getPhoneNumber(value)?.country;
    if (newCountryCode) setCountryCode(newCountryCode);
  }, [value]);

  const handleChange = (v: string): void => {
    let newValue = parseIncompletePhoneNumber(v);
    if (
      newValue === valueWithFormat &&
      formatIncompletePhoneNumber(newValue, countryCode).indexOf(v) === 0
    )
      newValue = newValue.slice(0, -1);
    let phoneNumber = getPhoneNumber(newValue);
    if (!!phoneNumber?.number) newValue = phoneNumber?.number as string;

    onChange({
      value: newValue,
      countryCode,
      countryCallingCode,
      isValid: phoneNumber?.isValid(),
    });
  };

  return (
    <div
      {...props}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "6px",
        border: "1px solid #ccc",
      }}
    >
      <CountrySelect value={countryCode} setValue={setCountryCode} />
      <TextInput
        {...textInputProps}
        leftIcon={<SvgMobile />}
        onChange={handleChange}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
    </div>
  );
};
