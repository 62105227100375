import { styled } from "styled-components";
import { Button } from "melodies-source/Button";
import { Body1, H4 } from "melodies-source/Text";
import { Layout, useSignupContext, ArtistInfo, ActionText } from "Components";
import { TwoColumns } from "Components/TwoColumns";
import { ReactComponent as HeadingIcon } from "assets/svg/match-found.svg";
import { ReactComponent as ArrowRightIcon } from "assets/svg/arrow-right.svg";
import { useState } from "react";

export const ArtistConfirm = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { data, handleNext, handleBack, handleSpotify } = useSignupContext();
  const handleManual = () => {
    handleNext(undefined, "matchesFound");
  };
  const handleSelect = () => {
    setLoading(true);
    handleSpotify().finally(() => setLoading(false));
  };

  return (
    <>
      <Layout
        cardProps={{
          size: "large",
          heading: { icon: <HeadingIcon />, text: "Match Found" },
          actions: {
            link: "contact",
            buttons: (
              <>
                <Button variant="outlined" onClick={() => handleBack()}>
                  Back
                </Button>
                <Button
                  rightIcon={<ArrowRightIcon />}
                  onClick={handleSelect}
                  disabled={loading}
                  loading={loading}
                >
                  This is Correct
                </Button>
              </>
            ),
          },
        }}
      >
        <Content>
          <div>
            <H4>Is this the right artist?</H4>
            <Body1>
              To ensure we populate this account with the correct information
              (often including streaming links, social media information and
              concert schedule), please confirm this is the right artist.
              <br />
              <br />
              If this doesn’t look right, you can click “Back” to search for
              another artist / see close matches and create a custom account if
              needed.
              <br />
              <br />
              <ActionText onClick={handleManual}>
                This is not the correct artist
              </ActionText>
            </Body1>
          </div>
          <div>
            <ArtistInfo artist={data.artist} release={data.release} />
          </div>
        </Content>
      </Layout>
    </>
  );
};

const Content = styled(TwoColumns)`
  & > div {
    ${H4} {
      margin-bottom: 20px;
    }
  }
`;
