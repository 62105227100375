import React from "react";
import styled, { css } from "styled-components";

interface Props {
  children: React.ReactNode;
  className?: string;
  isElevated?: boolean;
  style?: any;
}

export const Card = ({ children, isElevated = false, ...props }: Props) => {
  return (
    <Container isElevated={isElevated} {...props}>
      {children}
    </Container>
  );
};

const Container = styled.div<Props>`
  padding: 32px 48px;
  border-radius: 12px;
  border: solid 1px #999999;
  ${(p) =>
    p.isElevated &&
    css`
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.16);
      border: none;
    `}
`;
