import { useEffect, useState } from "react";
import {
  Layout,
  functions,
  useSignupContext,
  ActionText,
  CodeInput,
} from "Components";
import { TwoColumns } from "Components/TwoColumns";
import { styled } from "styled-components";
import { Body2, Error, H4 } from "melodies-source/Text";
import { ReactComponent as HeadingIcon } from "assets/svg/verification-sent.svg";
import { ReactComponent as VerificationCodeEmail } from "assets/svg/verification-code-email.svg";
import { useDesktopMediaQuery } from "hooks/useDesktopMediaQuery";
import { Button } from "melodies-source/Button";
import { httpsCallable } from "firebase/functions";
import { addToast } from "melodies-source/Toast";

export const VerificationSent = () => {
  const { data, active, handleNext, handleBack } = useSignupContext();
  const [code, setCode] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const isDesktop = useDesktopMediaQuery();
  const onboarding = httpsCallable<
    { action: string; email: string; signupId: string; code?: string },
    { status: string; signupId: string }
  >(functions, "signup-account");

  const resendVerifyEmail = () => {
    addToast(`Verification code resent to ${data.email}`, "success", {
      position: "top-right",
    });
    sendVerifyEmail();
  };

  const sendVerifyEmail = () => {
    onboarding({ action: "verify", signupId: active!.id, email: data.email });
  };
  useEffect(() => {
    sendVerifyEmail();
  }, []);
  const handleVerify = () => {
    setLoading(true);
    setHasError(false);
    onboarding({
      action: "verify",
      signupId: active!.id,
      email: data.email,
      code,
    })
      .then((res) => {
        if (res.data.status === "code-confirmed") {
          handleNext();
        } else {
          setHasError(true);
        }
        return res;
      })
      .catch(() => {
        setHasError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Layout
      cardProps={{
        size: "large",
        heading: { icon: <HeadingIcon />, text: "Verification Sent" },
        actions: {
          custom: (
            <span>
              Didn’t receive a code?{" "}
              <ActionText onClick={resendVerifyEmail}>Resend</ActionText>
            </span>
          ),
          buttons: (
            <>
              <Button variant="outlined" onClick={() => handleBack()}>
                Back
              </Button>
              <Button
                disabled={loading || code.length !== 6}
                variant="primary"
                onClick={handleVerify}
                loading={loading}
              >
                Verify
              </Button>
            </>
          ),
        },
      }}
    >
      <Content>
        <div>
          <H4>A six-digit code was sent to {data.email}</H4>
          <div>
            <CodeInput
              value={code}
              onChange={setCode}
              size={6}
              error={false}
              allow={/\d/}
            />
            <Body2>Please enter your verification code here</Body2>
            {hasError && <Error>Verification Code Not Valid</Error>}
          </div>
        </div>
        {isDesktop && <VerificationCodeEmail />}
      </Content>
    </Layout>
  );
};

const Content = styled(TwoColumns)`
  & > div {
    h4 {
      margin-bottom: 20px;
    }
  }

  ${Body2} {
    font-size: 14px;
    margin-top: 10px;
  }
`;
