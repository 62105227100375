import React from "react";
import { styled } from "styled-components";
import { LayoutProps } from "..";
import { useContactContext } from "Components/ContactContext";

type Props = Pick<LayoutProps, "flow">;

export const Footer: React.FC<Props> = ({ flow }) => {
  const { setShowContact } = useContactContext();
  return (
    <Container flow={flow}>
      <span>&copy; 2023 Set.Live</span>
      <Links>
        <a href="https://suite.set.live/" target="_blank" rel="noreferrer">
          About SET
        </a>
        <a
          href={`${process.env.REACT_APP_SL_URL}/privacy`}
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
        <a
          href={`${process.env.REACT_APP_SL_URL}/terms`}
          target="_blank"
          rel="noreferrer"
        >
          Fan Terms of Use
        </a>
        <a
          href={`${process.env.REACT_APP_SL_URL}/artist-terms`}
          target="_blank"
          rel="noreferrer"
        >
          Artist Terms of Use
        </a>
        <a href={`#contact`} onClick={() => setShowContact(true)}>
          Contact Us
        </a>
        {/* <a href="/" target="_blank">
          FAQ
        </a> */}
      </Links>
    </Container>
  );
};

const Container = styled.div<Props>`
  z-index: 1;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(0deg, #fff 0%, rgba(217, 217, 217, 0) 100%);
  padding: 40px;
  width: 100%;
  font-size: 14px;
  color: #000;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column-reverse;
    align-items: center;
    gap: 20px;
  }
`;

const Links = styled.div`
  display: flex;
  gap: 30px;

  a {
    text-decoration: none;
    color: inherit;
  }

  ${({ theme }) => theme.media.mobile} {
    flex-wrap: wrap;
    justify-content: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;

    a {
      flex-shrink: 0;
    }
  }
`;
