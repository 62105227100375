import { Body1, Body2, H2, H4 } from "melodies-source/Text";
import React from "react";
import { styled } from "styled-components";

interface Release {
  cover: string;
  name: string;
}

interface Artist {
  image: string;
  name: string;
  genre: string;
}

interface Props {
  artist: Artist;
  release: Release;
}

export const ArtistInfo: React.FC<Props> = ({ artist, release }) => {
  return (
    <Container>
      <Image src={artist.image} alt={artist.name} />
      <Info>
        <H2>{artist.name}</H2>
        <Body1>{artist.genre}</Body1>
      </Info>
      <ReleaseContainer>
        <img src={release?.cover} alt={release?.name} />
        <div>
          <Body2>Sample Release:</Body2>
          <H4>{release?.name}</H4>
        </div>
      </ReleaseContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
  gap: 20px;
  padding: 15px;
  border-radius: 6px;
  border: 1px solid #ccc;
  color: #333;
  background: #f7fafc;
  margin-top: 75px;
  padding: 30px;

  ${H2} {
    line-height: 32px;
  }

  ${H4} {
    line-height: 20px;
  }

  ${({ theme }) => theme.media.mobile} {
    padding: 20px;
    margin-top: 85px;
  }
`;

const Image = styled.img`
  width: 160px;
  height: 160px;
  border-radius: 50%;
  object-fit: cover;
  margin-top: -105px;
`;

const Info = styled.div`
  text-align: center;
  text-transform: capitalize;
`;

const ReleaseContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 10px;

  img {
    width: 100px;
    height: 100px;
  }

  ${Body2} {
    margin-bottom: 7px;
  }
`;
