import { useState } from "react";
import { styled } from "styled-components";
import {
  AritistInfoCompact,
  Layout,
  SpotifyArtistSearch,
  useContactContext,
  useSignupContext,
} from "Components";
import { TwoColumns } from "Components/TwoColumns";
import { Button } from "melodies-source/Button";
import { Body1, H4 } from "melodies-source/Text";
import { ReactComponent as ArrowRightIcon } from "assets/svg/arrow-right.svg";
import { ReactComponent as ArtistSearchIcon } from "assets/svg/artist-search.svg";

export const ArtistSearch = () => {
  const { handleNext, handleBack, handleLookup } = useSignupContext();
  const [artist, setArtist] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const { setShowContact } = useContactContext();

  const handleClick = () => {
    if (artist) {
      const data = {
        artist: {
          image: artist?.avatarSrc || "",
          name: artist?.label,
          genre: artist?.caption || "",
          spotifyId: artist?.value,
        },
      };
      setLoading(true);
      handleLookup(artist.value)
        .then((res) => {
          if (res === "taken") {
            handleNext(data, "existingArtist");
          } else {
            handleNext(data);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleAdd = (artistSearch: string) => {
    handleNext({ artistSearch }, "matchesFound");
  };

  const artistInfo = {
    image: artist?.avatarSrc || "",
    name: artist?.label,
    genre: artist?.caption || "",
  };

  return (
    <Layout
      cardProps={{
        size: "large",
        heading: { icon: <ArtistSearchIcon />, text: "Artist Search" },
        actions: {
          link: "login",
          buttons: (
            <>
              <Button
                variant="outlined"
                onClick={() => handleBack()}
                disabled={loading}
              >
                Back
              </Button>
              <Button
                disabled={loading || !artist}
                onClick={handleClick}
                rightIcon={<ArrowRightIcon />}
                loading={loading}
              >
                Next
              </Button>
            </>
          ),
        },
      }}
    >
      <TwoColumns gap="60px">
        <Description>
          <H4>Search our database for an artist.</H4>
          <Body1>
            Please enter the full or official name of the artist to ensure they
            are recognized by SET. This will mean a quicker account setup and a
            more seamless experience with our products.
            <br />
            <br /> The artist name can be formatted later in our system.
          </Body1>
        </Description>
        <SearchContainer>
          <H4>Which artist would you like to make an account for?</H4>
          <SpotifyArtistSearch onChange={setArtist} handleAdd={handleAdd} />
          {artist && (
            <AritistInfoCompact
              artist={artistInfo}
              onClose={() => {
                setArtist(null);
              }}
            />
          )}
        </SearchContainer>
      </TwoColumns>
    </Layout>
  );
};

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 400px;
`;

const Description = styled.div`
  ${H4} {
    margin-bottom: 20px;
  }
`;
