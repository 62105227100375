import { styled } from "styled-components";
import { Button } from "melodies-source/Button";
import { Body1, H4 } from "melodies-source/Text";
import {
  Layout,
  useSignupContext,
  ArtistInfo,
  useContactContext,
} from "Components";
import { TwoColumns } from "Components/TwoColumns";
import { ReactComponent as HeadingIcon } from "assets/svg/existing-artist.svg";

export const ExistingArtist = () => {
  const { data, handleNext, handleBack } = useSignupContext();
  const { setShowContact } = useContactContext();

  return (
    <Layout
      cardProps={{
        size: "large",
        heading: { icon: <HeadingIcon />, text: "Existing Artist" },
        actions: {
          link: "contact",
          buttons: (
            <>
              <Button variant="outlined" onClick={() => handleBack()}>
                Back
              </Button>
              <Button onClick={() => handleNext()}>Request Access</Button>
            </>
          ),
        },
      }}
    >
      <Content>
        <div>
          <H4>It looks like {data.artistName} is already on SET.</H4>
          <Body1>
            This artist is already using SET. You can request access to this
            artist’s account, or go back to search for a different artist.
            <br />
            <br />
            Do you believe this account has been claimed in error?{" "}
            <a href="#contact" onClick={() => setShowContact(true)}>
              Contact MAX
            </a>{" "}
            to help resolve the issue.
          </Body1>
        </div>
        <div>
          <ArtistInfo artist={data.artist} release={data.release} />
        </div>
      </Content>
    </Layout>
  );
};

const Content = styled(TwoColumns)`
  & > div {
    h4 {
      margin-bottom: 20px;
    }
  }
`;
