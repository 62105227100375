import { useState } from "react";
import { Layout, useSignupContext } from "Components";
import { styled } from "styled-components";
import { Body1, H4 } from "melodies-source/Text";
import { Select } from "melodies-source/Select";
import { TwoColumns } from "Components/TwoColumns";
import { Button } from "melodies-source/Button";
import { TextInput } from "melodies-source/TextInput";
import { ImageInput } from "melodies-source/ImageInput";
import { ReactComponent as HeadingIcon } from "assets/svg/artist-info.svg";
import { ReactComponent as ArrowRightIcon } from "assets/svg/arrow-right.svg";

const GENRE_OPTIONS = [
  { label: "Alternative", value: "Alternative" },
  { label: "Blues", value: "Blues" },
  { label: "Classical", value: "Classical" },
  { label: "Country", value: "Country" },
  { label: "Electronic/Dance", value: "Electronic/Dance" },
  { label: "Folk", value: "Folk" },
  { label: "Gospel", value: "Gospel" },
  { label: "Hip Hop/Rap", value: "Hip Hop/Rap" },
  { label: "Indie", value: "Indie" },
  { label: "Jazz", value: "Jazz" },
  { label: "K-Pop", value: "K-Pop" },
  { label: "Latin", value: "Latin" },
  { label: "Metal", value: "Metal" },
  { label: "Pop", value: "Pop" },
  { label: "R&B", value: "R&B" },
  { label: "Rock", value: "Rock" },
  { label: "Soul", value: "Soul" },
  { label: "Spoken Word", value: "Spoken Word" },
  { label: "World Music", value: "World Music" },
  { label: "Not a Music Artist", value: "Not a Music Artist" },
];

export const ArtistInfo = () => {
  const [loading, setLoading] = useState(false);
  const { handleSave, handleBack, handleSpotify } = useSignupContext();
  const [website, setWebsite] = useState("");
  const [merchStore, setMerchStore] = useState("");
  const [genre, setGenre] = useState("");
  const [image, setImage] = useState<Nullable<Blob>>(null);
  const [imageUrl, setImageUrl] = useState<string>("");

  const handleClick = () => {
    setLoading(true);
    handleSave({ artistImage: imageUrl, website, merchStore, genre })
      .then(() => handleSpotify(true))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Layout
      cardProps={{
        size: "large",
        heading: { icon: <HeadingIcon />, text: "Artist Info" },
        actions: {
          link: "contact",
          buttons: (
            <>
              <Button variant="outlined" onClick={() => handleBack()}>
                Back
              </Button>
              <Button
                disabled={loading || !genre || !imageUrl}
                rightIcon={<ArrowRightIcon />}
                onClick={handleClick}
                loading={loading}
              >
                Next
              </Button>
            </>
          ),
        },
      }}
    >
      <Content>
        <div>
          <H4>To create a new account, please tell us about this artist.</H4>
          <Body1>
            We use the artist’s website to try and pull various information like
            social media accounts and streaming platforms. This ensures a
            seamless setup experience in our system and allows you to get
            started right away.
          </Body1>
        </div>
        <Form>
          <TextInput
            label="Artist Website"
            placeholder="Please paste artist website"
            value={website}
            onChange={setWebsite}
          />
          <TextInput
            label="Merch Store (optional)"
            placeholder="Please paste artist merch store..."
            value={merchStore}
            onChange={setMerchStore}
          />
          <Select
            placeholder="Please select your genre..."
            value={genre}
            onChange={setGenre}
            label="Genre"
            options={GENRE_OPTIONS}
          />
          <ImageInput
            value={image}
            onSelect={setImage}
            onUrlChange={setImageUrl}
            label="Upload Image"
            openModalText="Upload Artist Image"
            aspectRatio={1}
          />
        </Form>
      </Content>
    </Layout>
  );
};

const Content = styled(TwoColumns)`
  & > div {
    h4 {
      margin-bottom: 20px;
    }
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
