import { useState, useEffect } from "react";
import { functions } from "@max/common/signup/functions";
import { AritistInfoCompact, ArtistCompact } from "Components";
import { LinkText } from "Components/LinkText";
import { AutocompleteAsync, OptionProps } from "melodies-source/Autocomplete";
import { ListItem } from "melodies-source/ListItem";
import { firebaseApp } from "../../Components/Firebase";
import { styled } from "styled-components";

export const useSpotify = () => {
  const [accessToken, setAccessToken] = useState("");

  useEffect(() => {
    functions.spotify(firebaseApp).then((res) => {
      setAccessToken(res.data.token);
    });
  }, []);

  return accessToken;
};

export const useSpotifyRecentRelease = () => {
  const token = useSpotify();
  const artistParameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return (artistId: string) => {
    return fetch(
      `https://api.spotify.com/v1/artists/${artistId}/albums`,
      artistParameters,
    )
      .then((res) => res.json())
      .then((data) => {
        return data?.items;
      });
  };
};

export const useSpotifySearch = (searchInput: string) => {
  const token = useSpotify();
  const [res, setRes] = useState<ArtistCompact[]>([]);
  const artistParameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const url = new URL("https://api.spotify.com/v1/search");
  url.searchParams.set("type", "artist");
  url.searchParams.set("limit", "5");

  const search = async (searchInput: string) => {
    url.searchParams.set("q", searchInput);
    return fetch(url, artistParameters)
      .then((res) => res.json())
      .then((data) => {
        return data.artists?.items?.map((i: any) => ({
          value: i.id,
          label: i.name,
          avatarSrc: i.images?.[0]?.url,
          caption: i.genres.slice(0, Math.min(2, i.genres.length)).join("/"),
          image: i.images?.[0]?.url,
          name: i.name,
          genre: i.genres.slice(0, Math.min(2, i.genres.length)).join("/"),
          raw: i,
        }));
      });
  };

  useEffect(() => {
    let cancel = false;
    if (searchInput && token) {
      search(searchInput).then((res) => {
        if (!cancel) {
          setRes(res);
        }
      });
      return () => {
        cancel = true;
      };
    }
  }, [searchInput, token]);
  return res;
};

export const SpotifyArtistSearch = ({
  onChange,
  className,
  handleAdd,
}: {
  onChange: (...p: any) => void;
  className?: string;
  handleAdd: (artistSearch: string) => void;
}) => {
  const token = useSpotify();
  const [text, setText] = useState<string>("");

  const artistParameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const url = new URL("https://api.spotify.com/v1/search");
  url.searchParams.set("type", "artist");

  const search = async (searchInput: string) => {
    if (searchInput.length < 1) {
      return new Promise((resolve) => {
        resolve([]);
      });
    }
    url.searchParams.set("q", searchInput);
    return fetch(url, artistParameters)
      .then((res) => res.json())
      .then((data) => {
        return data.artists?.items?.map((i: any) => ({
          value: i.id,
          label: i.name,
          avatarSrc:
            i.images?.[0]?.url ||
            "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==",
          caption: i.genres.slice(0, Math.min(2, i.genres.length)).join("/"),
          raw: i,
        }));
      });
  };

  return (
    <div className={className}>
      <AutocompleteAsync
        label="Artist Search"
        placeholder="Type Artist Name..."
        getOptions={search}
        text={text}
        setText={setText}
        customOption={ArtistOption}
        onChange={(value) => {
          onChange(value);
          setText("");
        }}
        defaultOption={
          <StyledLinkText onClick={() => handleAdd(text)}>
            Manually add "{text}"
          </StyledLinkText>
        }
      />
    </div>
  );
};

const ArtistOption = ({
  label,
  avatarSrc,
  caption,
  caption2,
  isSelected,
  icon,
  ...props
}: OptionProps) => {
  const info = { image: avatarSrc || "", name: label, genre: caption || "" };

  return (
    <ListItem {...props} isSelected={isSelected}>
      <AritistInfoCompact artist={info} isOption />
    </ListItem>
  );
};

const StyledLinkText = styled(LinkText)`
  margin: 8px 12px;
  padding: 15px 0;
`;
