import { SvgUpload } from "../../../Svgs/Upload";
import { colors } from "../../../Theme/colors";
import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { useImageInputContext } from "../../ImageInputContextProvider";
import { happyCloudSvg, sadCloudSvg } from "./svgs";
import { Body1, Body2, Label } from "../../../Text";
import { ReactComponent as ImageIcon } from "assets/svg/image.svg";

const PlaceholderText = styled(Label)`
  ${(props) => props.theme.mediaQueries.mobile} {
    width: 80%;
  }
`;

const contentOptions = {
  inactive: {
    message: "Drop your image here",
    icon: (
      <div style={{ color: "#cccccc" }}>
        <ImageIcon />
      </div>
    ),
  },
  active: {
    valid: {
      message: "Drop It!",
      icon: happyCloudSvg,
    },
    invalid: {
      message: "Are you sure this is an image?",
      icon: sadCloudSvg,
    },
  },
};

type Props = {
  Wrapper?: React.FC;
  onSelectImage: (img: any) => void;
};
const ImageDropzone = ({ onSelectImage }: Props) => {
  const { dropzoneDescription } = useImageInputContext();

  const dzProps = useDropzone({
    accept: { "image/*": [] },
    onDrop: ([file]) => {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => onSelectImage(reader.result);
      }
    },
  });
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    fileRejections,
  } = dzProps;

  const content = !isDragActive
    ? contentOptions.inactive
    : contentOptions.active[isDragAccept ? "valid" : "invalid"];

  const contentUI = (
    <div
      style={{
        marginTop: 10,
        padding: "40px 20px",
        borderRadius: 6,
        background: "#F5F8FA",
      }}
    >
      <div style={{ margin: "-10px auto 0 auto" }}>{content.icon}</div>
      <div style={{ marginTop: 10, fontWeight: "bold" }}>
        <span>{content.message}</span>
        {!isDragActive && (
          <span>
            , or{" "}
            <span
              style={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Browse
            </span>
          </span>
        )}
        <div style={{ margin: "10px auto 0" }}>
          <PlaceholderText>Supports JPEG and PNG</PlaceholderText>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div style={{ textAlign: "center" }}>{contentUI}</div>
      </div>
      <Errors style={{ marginTop: 20, color: "red" }}>
        {fileRejections.map(({ errors, file }) =>
          errors.map((error) => <li>{error.message}</li>)
        )}
      </Errors>
    </div>
  );
};

export default ImageDropzone;

const Errors = styled.ul`
  margin-top: 20px;
  list-style: unset;
  padding-left: 20px;

  li {
    color: #ef5350;
    font-size: 14px;
  }
`;
