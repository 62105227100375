import { useState } from "react";
import { Layout, useSignupContext } from "Components";
import { styled } from "styled-components";
import { TwoColumns } from "Components/TwoColumns";
import { Body1 } from "melodies-source/Text";
import { TextInput } from "melodies-source/TextInput";
import { Textarea } from "melodies-source/Textarea";
import { Button } from "melodies-source/Button";
import { ReactComponent as HeadingIcon } from "assets/svg/contact-info.svg";
import { ReactComponent as ArrowRightIcon } from "assets/svg/arrow-right.svg";

export const AdditionalInfo = () => {
  const { data, handleNext, handleBack } = useSignupContext();
  const [profileLink, setProfileLink] = useState("");
  const [companyLink, setCompanyLink] = useState("");
  const [workAndPlan, setWorkAndPlan] = useState("");

  const formIsValid = profileLink && companyLink && workAndPlan;

  return (
    <Layout
      cardProps={{
        size: "large",
        heading: { icon: <HeadingIcon />, text: "Additional Info" },
        actions: {
          link: "contact",
          buttons: (
            <>
              <Button variant="outlined" onClick={() => handleBack()}>
                Back
              </Button>
              <Button
                disabled={!formIsValid}
                onClick={() =>
                  handleNext({
                    profileLink,
                    companyLink,
                    workAndPlan,
                  })
                }
                rightIcon={<ArrowRightIcon />}
              >
                Next
              </Button>
            </>
          ),
        },
      }}
    >
      <TwoColumns>
        <div>
          <Body1>
            For additional security, we require verification showing that you
            and {data.companyName} work with {data.artistName}.
          </Body1>
        </div>
        <Form>
          <TextInput
            label={`Please provide a link showing that you work at ${
              data.companyName || data.artistName
            }. This can be a company page with your profile, or a LinkedIn profile that shows your affiliation.`}
            placeholder="Please paste a profile link"
            value={profileLink}
            onChange={setProfileLink}
          />
          <TextInput
            label={`Please provide a link showing that ${
              data.companyName || data.artistName
            } works with ${
              data.artistName
            }. This can be an artist roster, or any other URL that demonstrates that this artist is a client.`}
            placeholder="Please paste a company link"
            value={companyLink}
            onChange={setCompanyLink}
          />
          <Textarea
            label="Please describe how this company works with the artist, and how you plan to use SET."
            placeholder="Type your response..."
            value={workAndPlan}
            onChange={setWorkAndPlan}
          />
        </Form>
      </TwoColumns>
    </Layout>
  );
};

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
