import React from "react";
import styled from "styled-components";
import { Button, ButtonType } from "../Button";
import { Portal } from "../common/Portal";
import { SvgCloseLarge } from "../Svgs/CloseLarge";
import { Body1, H2, Label } from "../Text";
import { useOverlay } from "../utils/hooks";

export const ModalContainer = styled.div`
  top: 0px;
  left: 0px;
  margin: 0px;
  position: fixed;
  display: block;
  z-index: 500;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`;

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: black;
  opacity: 0.8;
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  //color: #ffffff;
  padding: 34px 40px;
  margin: max(12vh, 40px) auto 40px;
  flex-shrink: 0;
  border-radius: 8px;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  ${(props) => props.theme.mediaQueries.mobile} {
    width: 90%;
    padding: 24px 20px;
  }
`;

export const ModalCloseIcon = styled.div`
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 150ms cubic-bezier(0, 0, 0.3, 1);
  //color: #ffffff;
  cursor: pointer;
  /* &:hover {
    color: #ffffff80;
  } */
  ${({ theme }) => theme.mediaQueries.mobile} {
    margin: -4px 0;
  }
`;

export const ModalConfirmation = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;

  ${({ theme }) => theme.mediaQueries.mobile} {
    ${H2} {
      font-size: 20px;
      line-height: 28px;
    }
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex-grow: 1;
`;

const ModalTypes = ["small", "medium", "large"] as const;
export type ModalType = (typeof ModalTypes)[number];

type ModalWidths = {
  [K in ModalType]?: any;
};
const modalWidths: ModalWidths = {
  small: 476,
  medium: 676,
  large: 876,
};

export type ModalConfirmationProps = {
  isDisabled?: boolean;
  label?: string;
  variant?: ButtonType;
  onConfirm: () => void;
  cancelLabel?: string;
};

export type ModalProps = {
  isOpen?: boolean;
  onClose?: () => void;
  children?: any;
  header?: string;
  variant?: ModalType;
  body?: string;
  subtitle?: string;
  withCloseIcon?: boolean;
  withBackdropClose?: boolean;
  className?: string;
  confirmation?: ModalConfirmationProps;
  props?: any;
  style?: React.CSSProperties;
};
export const Modal = ({
  isOpen = false,
  onClose = () => {},
  header,
  body,
  subtitle,
  variant = "small",
  children,
  withCloseIcon = true,
  withBackdropClose = true,
  className = "",
  confirmation,
  ...props
}: ModalProps) => {
  useOverlay(isOpen);

  const headerUI = (
    <ModalHeader>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <H2>{header}</H2>
        {subtitle && <Label style={{ marginTop: 4 }}>{subtitle}</Label>}
      </div>
      {withCloseIcon && (
        <ModalCloseIcon onClick={onClose} style={{ marginLeft: 12 }}>
          <SvgCloseLarge />
        </ModalCloseIcon>
      )}
    </ModalHeader>
  );

  const confirmationUI = confirmation && (
    <ModalConfirmation>
      <div style={{ display: "flex" }}>
        <Button text onClick={onClose} style={{ width: 120 }}>
          {confirmation.cancelLabel || "Cancel"}
        </Button>
        <Button
          disabled={!!confirmation?.isDisabled || false}
          variant={confirmation.variant}
          onClick={() => confirmation?.onConfirm()}
          style={{ marginLeft: 20, minWidth: 120, display: "inline-flex" }}
        >
          {confirmation.label || "Confirm"}
        </Button>
      </div>
    </ModalConfirmation>
  );

  return (
    <>
      {isOpen && (
        <Portal>
          <div className={className}>
            <ModalBackdrop />
            <ModalContainer
              onClick={() => {
                if (withBackdropClose) onClose();
              }}
            >
              <ModalWrapper
                {...props}
                style={{ maxWidth: modalWidths[variant], ...props.style }}
                onClick={(e) => e.stopPropagation()}
              >
                {headerUI}

                <ModalContent>
                  {body && <Body1 style={{ marginTop: 12 }}>{body}</Body1>}
                  {children}
                </ModalContent>

                {confirmationUI}
              </ModalWrapper>
            </ModalContainer>
          </div>
        </Portal>
      )}
    </>
  );
};
