import { useState } from "react";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import { Button } from "melodies-source/Button";
import { Body1, H4 } from "melodies-source/Text";
import {
  Layout,
  useSignupContext,
  AritistInfoCompact,
  ArtistCompact,
  useSpotifySearch,
} from "Components";
import { TwoColumns } from "Components/TwoColumns";
import { ReactComponent as HeadingIcon } from "assets/svg/match-found.svg";
import { ReactComponent as QuestionMark } from "assets/svg/question-mark.svg";
import { ReactComponent as Lightbulb } from "assets/svg/lightbulb.svg";
import { ReactComponent as ArrowRightIcon } from "assets/svg/arrow-right.svg";

export const MatchesFound = () => {
  const { data, handleNext, handleBack } = useSignupContext();
  const matches = useSpotifySearch(data.artistSearch);
  const [selectedArtist, setSelectedArtist] = useState<ArtistCompact>();
  const handleCreate = () => {
    handleNext(undefined, "artistInfo");
  };
  const handleClick = () => {
    const data = {
      artist: {
        image: selectedArtist?.image || "",
        name: selectedArtist?.name,
        genre: selectedArtist?.genre || "",
        //@ts-ignore
        spotifyId: selectedArtist?.value,
      },
    };
    handleNext(data);
  };

  return (
    <Layout
      cardProps={{
        size: "large",
        heading:
          matches.length > 0
            ? { icon: <HeadingIcon />, text: "Matches Found" }
            : { icon: <QuestionMark />, text: "Artist not recognized" },
        actions: {
          link: "contact",
          buttons: (
            <>
              <Button variant="outlined" onClick={() => handleBack()}>
                Back
              </Button>
              <Button
                disabled={!selectedArtist}
                onClick={handleClick}
                rightIcon={<ArrowRightIcon />}
              >
                Next
              </Button>
            </>
          ),
        },
      }}
    >
      {matches.length > 0 ? (
        <Content>
          <div>
            <H4>We found some potential artist matches.</H4>
            <Body1>
              Please review these artists with similar names. Selecting your
              artist from this list allows us to instantly populate the artist’s
              account with essential details, concert schedules, social links
              and more.
              <br />
              <br />
              If none of these look right, you can{" "}
              <Link to="" onClick={handleCreate}>
                create a new account
              </Link>{" "}
              for {data.artistSearch}. <br />
              <br />
            </Body1>
          </div>
          <Matches>
            {matches.map((artist) => (
              <AritistInfoCompact
                artist={artist}
                onSelect={setSelectedArtist}
                //@ts-ignore
                isSelected={artist.value === selectedArtist?.value}
              />
            ))}
          </Matches>
        </Content>
      ) : (
        <Content>
          <div>
            <H4>
              Sorry, “{data.artistSearch}” is not an artist recognized by SET.
            </H4>
            <Body1>
              Please confirm the spelling of the artist's name and{" "}
              <Link to="" onClick={() => handleBack()}>
                try searching again.
              </Link>
              <br />
              <br />
              If you use an abbreviation or a nickname, try entering the full or
              official name instead. This ensures you can benefit from the
              automation features we offer. This name can be formatted later in
              our system.
              <br />
              <br />
              If this artist name is correct, you can{" "}
              <Link to="" onClick={handleCreate}>
                create a new account.
              </Link>
            </Body1>
          </div>
          <NoMatch>
            <Lightbulb />
            <H4>
              Having an artist name recognized by SET means a quicker account
              setup and a more seamless experience with our products.
            </H4>
          </NoMatch>
        </Content>
      )}
    </Layout>
  );
};

const Content = styled(TwoColumns)`
  & > div:first-child {
    h4 {
      margin-bottom: 20px;
    }
  }
`;

const Matches = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px;
  max-height: 420px;
  overflow-y: scroll;
`;

const NoMatch = styled.div`
  display: flex;
  flex-direction: column;
  background: #f5f8fa;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  padding: 80px 40px;
  text-align: center;
`;
