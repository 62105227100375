import { useState } from "react";
import { Affiliation } from "@max/common/signup/types";
import { Layout, useSignupContext } from "Components";
import { styled } from "styled-components";
import { Body1, H4 } from "melodies-source/Text";
import { Select } from "melodies-source/Select";
import { Modal } from "melodies-source/Modal";
import { TwoColumns } from "Components/TwoColumns";
import { Button } from "melodies-source/Button";
import { ReactComponent as HeadingIcon } from "assets/svg/your-affiliation.svg";
import { ReactComponent as ArrowRightIcon } from "assets/svg/arrow-right.svg";

export const AFFILIATION_OPTIONS: { label: string; value: Affiliation }[] = [
  { label: "I'm the Artist", value: "artist" },
  { label: "Artist's Management Company", value: "management-company" },
  { label: "Artist's Marketing/Consulting Firm", value: "marketing" },
  { label: "Artist's Music Label", value: "label" },
  { label: "Artist's Tour Crew", value: "crew" },
  { label: "Other", value: "other" },
];

export const ArtistAffiliation = () => {
  const { data, handleNext, handleBack } = useSignupContext();
  const [selectedAffiliation, setSelectedAffiliation] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <Layout
      cardProps={{
        size: "large",
        heading: { icon: <HeadingIcon />, text: "Your Affiliation" },
        actions: {
          link: "contact",
          buttons: (
            <>
              <Button variant="outlined" onClick={() => handleBack()}>
                Back
              </Button>
              <Button
                disabled={!selectedAffiliation}
                rightIcon={<ArrowRightIcon />}
                onClick={() => handleNext({ affiliation: selectedAffiliation })}
              >
                Next
              </Button>
            </>
          ),
        },
      }}
    >
      <Content>
        <div>
          <H4>
            What is your affiliation with {data.artistName || data.artistSearch}
            ?
          </H4>
          <Body1>
            With so many people potentially involved in the success of this
            artist, we want to make sure that your SET experience is tailored to
            your role and that we are granting access to the right person.
            <br />
            <br />
            <LinkText onClick={() => setModalIsOpen(true)}>
              Not sure which to choose?
            </LinkText>
          </Body1>
        </div>
        <div>
          <Select
            placeholder="Choose Affiliation..."
            value={selectedAffiliation}
            onChange={setSelectedAffiliation}
            label="Choose what best describes you:"
            options={AFFILIATION_OPTIONS}
          />
        </div>
      </Content>
      <Modal
        isOpen={modalIsOpen}
        header="Artist Affiliations"
        onClose={() => setModalIsOpen(false)}
        variant="medium"
      >
        <ModalContent>
          <H4>Artist</H4>
          <Body1>Choose this option if you are the music artist.</Body1>
          <H4>Management Company</H4>
          <Body1>
            Select this option if you're part of the artist's management company
            in any capacity.
          </Body1>
          <H4>Marketing/Consulting Firm</H4>
          <Body1>
            Choose this option if you work for a third-party marketing or
            consulting firm helping the artist, the artist's management team or
            the artist's label grow the artist's fan base or community.
          </Body1>
          <H4>Music Label</H4>
          <Body1>
            Select this option if you work for the label's marketing, social
            media, CRM team or any other capacity.
          </Body1>
          <H4>Tour Crew</H4>
          <Body1>
            Choose this if you're the tour manager or part of the crew on the
            road helping to make the tour happen.
          </Body1>
        </ModalContent>
      </Modal>
    </Layout>
  );
};

const Content = styled(TwoColumns)`
  & > div {
    h4 {
      margin-bottom: 20px;
    }
  }
`;

const ModalContent = styled.div`
  ${H4} {
    margin-top: 20px;
  }
`;

const LinkText = styled.span`
  color: #00e;
  cursor: pointer;
  text-decoration: underline;
`;
