import React, { CSSProperties } from "react";
import toast, {
  ToastBar,
  Toaster,
  ToastOptions,
  ToastPosition,
  ToastType,
} from "react-hot-toast";
import styled from "styled-components";
import { SvgCloseLarge } from "../Svgs/CloseLarge";
import { Body1 } from "../Text";

const defaultDuration = 5000;
const defaultPosition: ToastPosition = "bottom-left";
const toastBorderRadius = 6;

export const addToast = (
  msg: JSX.Element | string,
  type: ToastType = "blank",
  toastOptions: ToastOptions = {}
): void => {
  const _toastOptions: ToastOptions = {
    duration: defaultDuration,
    position: defaultPosition,
    ...toastOptions,
  };
  //@ts-ignore
  const toastFn = type === "blank" ? toast : toast[type];
  toastFn(msg, _toastOptions);
};

const toastContainerCss: CSSProperties = {
  padding: 0,
  borderRadius: toastBorderRadius,
  boxShadow: "none",
};

const ToastWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px;
  border-radius: ${toastBorderRadius}px;
  max-width: 600px;
  svg {
    display: flex;
    align-items: center;
  }
`;

const IconWrapper = styled.div`
  padding: 5px;
  margin-left: 10px;
`;

export const Toast = () => {
  return (
    <Toaster
      toastOptions={{
        style: toastContainerCss,
        success: {
          iconTheme: {
            primary: "white",
            secondary: "#17B55F",
          },
          style: {
            background: "#17B55F",
            color: "white",
          },
        },
        error: {
          iconTheme: {
            primary: "white",
            secondary: "#ff4b4b",
          },
          style: {
            background: "#ff4b4b",
            color: "white",
          },
        },
      }}
    >
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => {
            return (
              <ToastWrapper>
                {icon}
                <Body1>{message}</Body1>
                <IconWrapper onClick={() => toast.dismiss(t.id)}>
                  <SvgCloseLarge height={18} />
                </IconWrapper>
              </ToastWrapper>
            );
          }}
        </ToastBar>
      )}
    </Toaster>
  );
};
