import React, { ReactNode } from "react";
import { styled } from "styled-components";
import { Menu } from "./Menu";
import { Content } from "./Content";
import { CardProps } from "./Content/Card";

interface Props {
  children: ReactNode;
  className?: string;
  cardProps?: Omit<CardProps, "children">;
  flow?: "signup" | "login";
}

export type LayoutProps = Props;

export const Layout: React.FC<Props> = ({ flow = "signup", ...props }) => {
  return (
    <Container flow={flow}>
      {flow === "signup" && <Menu />}
      <Content flow={flow} {...props} />
    </Container>
  );
};

type ContainerProps = Pick<Props, "flow">;

const Container = styled.div<ContainerProps>`
  display: grid;
  grid-template-rows: ${({ flow }) =>
    flow === "signup" ? "auto 1fr" : " 1fr"};
  height: 100%;
`;
