import React, { useState, Dispatch, SetStateAction, useRef } from "react";
import styled, { css } from "styled-components";
import { useDesktopMediaQuery } from "hooks/useDesktopMediaQuery";

interface Props {
  value: string;
  onChange: Dispatch<SetStateAction<string>>;
  size: number;
  error: boolean;
  allow?: RegExp;
}

const GAP = 10;
const CELL_WIDTH = 40;
const OFFSET = 7;

export const CodeInput: React.FC<Props> = ({
  onChange,
  size,
  error,
  allow,
}) => {
  const input = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState("");
  const [focused, setFocused] = useState(false);
  const isDesktop = useDesktopMediaQuery();

  const handleClick = () => {
    input.current?.focus();
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Backspace") {
      const updatedValue = value.slice(0, value.length - 1);
      setValue(updatedValue);
      onChange(updatedValue);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const updatedValue =
      (value && value.length === size) || (allow && !allow.test(newValue))
        ? value
        : (value + newValue).slice(0, size);
    setValue(updatedValue);
    onChange(updatedValue);
  };

  const values = value ? value.split("") : [];

  const selectedIndex = values.length < size ? values.length : size - 1;

  const gap = isDesktop ? GAP : GAP / 2;
  const offset = isDesktop ? OFFSET : OFFSET / 2 + 1;

  return (
    <InputContainer onClick={handleClick}>
      <Input
        value=""
        ref={input}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        onFocus={handleFocus}
        onBlur={handleBlur}
        leftPosition={`${
          selectedIndex * (CELL_WIDTH + gap) -
          offset +
          (value.length === size ? offset * 2 : 0)
        }px`}
      />
      {new Array(size).fill(0).map((_, index) => {
        const selected = values.length === index;
        const filled = values.length === size && index === size - 1;
        return (
          <InputCell
            key={index}
            selected={selected}
            filled={filled}
            focused={focused}
            error={error}
          >
            {values[index]}
          </InputCell>
        );
      })}
    </InputContainer>
  );
};

type InputCellProps = Pick<Props, "error"> & {
  selected: boolean;
  filled: boolean;
  focused: boolean;
};

const InputCell = styled.div<InputCellProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #c6c6c6;
  border-radius: 4px;
  background-color: transparent;
  width: ${CELL_WIDTH}px;
  height: 45px;
  font-size: 22px;
  color: #1b0076;

  ${(props) =>
    props.error &&
    css`
      border-color: #fa5252;
      border-width: 2px;
    `};

  ${(props) =>
    (props.selected || props.filled) &&
    props.focused &&
    css`
      border-color: #1b0076;
    `}
`;

interface InputProps {
  leftPosition: string;
}
const Input = styled.input<InputProps>`
  width: ${CELL_WIDTH}px;
  top: 0px;
  bottom: 0px;
  position: absolute;
  border: none;
  font-size: 22px;
  text-align: center;
  background-color: transparent;
  outline: none;
  left: ${(props) => props.leftPosition};
  z-index: 1;
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  gap: ${GAP}px;

  ${({ theme }) => theme.media.mobile} {
    gap: ${GAP / 2}px;
  }
`;
