import { Layout, useSignupContext } from "Components";
import { useDesktopMediaQuery } from "hooks/useDesktopMediaQuery";
import { Button } from "melodies-source/Button";
import { Body1, H1, H2 } from "melodies-source/Text";
import { styled } from "styled-components";
import { ReactComponent as SendIcon } from "assets/svg/send-circle-green.svg";

export const RequestSent = () => {
  const isDesktop = useDesktopMediaQuery();
  const { data } = useSignupContext();

  const handleDone = () => {
    window.location.href = process.env.REACT_APP_ARTIST_PORTAL_URL as string;
  };

  const Heading = isDesktop ? H1 : H2;
  return (
    <Layout>
      <Container>
        <ArtistImageContainer>
          <ArtistImage url={data.artistImage} />
          <StyledSendIcon />
        </ArtistImageContainer>
        <Heading>Request Sent</Heading>
        <Body1>
          Your request to join {data.artistName} was successfully submitted. An
          admin will review your request shortly.
        </Body1>
        <StyledButton onClick={handleDone}>Go to your dashboard</StyledButton>
      </Container>
    </Layout>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  ${Body1} {
    text-align: center;
  }
`;

interface ArtistImageProps {
  url: string;
}

const ArtistImage = styled.img<ArtistImageProps>`
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background-image: ${({ url }) => `url(${url})`};
  background-size: cover;
  background-position: center;
  border: 2px solid #fff;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.25);
`;

const ArtistImageContainer = styled.div`
  position: relative;
`;

const StyledSendIcon = styled(SendIcon)`
  position: absolute;
  top: -6px;
  right: -6px;
`;
const StyledButton = styled(Button)`
  margin: 30px 0 10px 0;

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
  }
`;
