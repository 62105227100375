import { CountryCode } from "libphonenumber-js/types";
import React, { useState } from "react";
import styled from "styled-components";
import { Avatar } from "../Avatar";
import { Dropdown } from "../Dropdown";
import { SvgCheck } from "../Svgs/Check";
import { SvgDropdown } from "../Svgs/Dropdown";
import { ListItem } from "../ListItem";
import { Body1 } from "../Text";
import { countryList as countryListBase } from "./countryList";

const Wrapper = styled.div`
  width: 140px;
  height: 42px;
  border-right: none;
  border-radius: 6px 0 0 6px;
  padding: 10px 12px;
  background: #fff;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
`;

const countryList = countryListBase.map((country) => ({
  ...country,
  image: `https://flagcdn.com/w160/${country.value.toLowerCase()}.png`,
}));

type Props = {
  value: CountryCode;
  setValue: React.Dispatch<React.SetStateAction<CountryCode>>;
};
export const CountrySelect = ({ value, setValue }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownUI = (
    <Dropdown
      style={{ width: "280px", maxHeight: "250px", marginTop: "-22px" }}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
    >
      {countryList.map((option, index) => (
        <div key={index}>
          <Option
            isSelected={value === option.value}
            {...{ setValue, setIsOpen, ...option }}
          />
        </div>
      ))}
    </Dropdown>
  );

  return (
    <>
      {dropdownUI}
      <Wrapper onClick={() => setIsOpen(true)}>
        <div style={{ display: "flex" }}>
          <Avatar
            style={{ width: "20px", height: "20px" }}
            imgUrl={countryList.find((c) => c.value === value)?.image}
          />
          <Body1 style={{ marginLeft: "10px" }}>{value.toUpperCase()}</Body1>
        </div>
        <SvgDropdown />
      </Wrapper>
    </>
  );
};

type OptionProps = {
  image: string;
  isSelected: boolean;
  value: string;
  setValue: any;
  label: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const Option = ({
  image,
  isSelected,
  value,
  setValue,
  label,
  setIsOpen,
}: OptionProps) => {
  return (
    <ListItem
      leftIcon={
        <>
          {isSelected ? (
            <SvgCheck />
          ) : (
            <Avatar style={{ width: "20px", height: "20px" }} imgUrl={image} />
          )}
        </>
      }
      onClick={() => {
        setValue(value);
        setIsOpen(false);
      }}
    >
      {label}
    </ListItem>
  );
};
