import { useState } from "react";
import { styled } from "styled-components";
import {
  AritistInfoCompact,
  Layout,
  SpotifyArtistSearch,
  useSignupContext,
} from "Components";
import { TwoColumns } from "Components/TwoColumns";
import { Button } from "melodies-source/Button";
import { Body1, H4 } from "melodies-source/Text";
import { ReactComponent as ArrowRightIcon } from "assets/svg/arrow-right.svg";
import { ReactComponent as AddUser } from "assets/svg/AddUser.svg";

export const AddArtist = () => {
  const { handleNext, handleBack, handleLookup, data } = useSignupContext();
  const [artist, setArtist] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleClick = () => {
    if (artist) {
      const data = {
        artist: {
          image: artist?.avatarSrc || "",
          name: artist?.label,
          genre: artist?.caption || "",
          spotifyId: artist?.value,
        },
      };
      setLoading(true);
      handleLookup(artist.value)
        .then((res) => {
          if (res === "taken") {
            handleNext(data, "existingArtist");
          } else {
            handleNext(data);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleAdd = (artistSearch: string) => {
    handleNext({ artistSearch }, "matchesFound");
  };

  const handleReturnToAP = () => {
    window.location.href = process.env.REACT_APP_ARTIST_PORTAL_URL as string;
  };

  const artistInfo = {
    image: artist?.avatarSrc || "",
    name: artist?.label,
    genre: artist?.caption || "",
  };

  return (
    <Layout
      cardProps={{
        size: "large",
        heading: { icon: <AddUser />, text: "Add Artist" },
        actions: {
          buttons: (
            <>
              {data.emailVerified ? (
                <Button
                  variant="outlined"
                  onClick={() => handleReturnToAP()}
                  disabled={loading}
                >
                  Back
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  onClick={() => handleBack()}
                  disabled={loading}
                >
                  Back
                </Button>
              )}
              <Button
                disabled={loading || !artist}
                onClick={handleClick}
                rightIcon={<ArrowRightIcon />}
                loading={loading}
              >
                Next
              </Button>
            </>
          ),
        },
      }}
    >
      <TwoColumns>
        <div>
          <Body1>
            Search for and add an additional artist to your account. You will be
            asked for your affiliation with this artist and to provide any
            security verification required by this artist.
            <br />
            <br />
            You will be able to access this artist account using the same
            credentials you currently use to log in to SET.
          </Body1>
        </div>
        <SearchContainer>
          <H4>Which artist would you like to make a SET profile for?</H4>
          <SpotifyArtistSearch onChange={setArtist} handleAdd={handleAdd} />
          {artist && (
            <AritistInfoCompact
              artist={artistInfo}
              onClose={() => {
                setArtist(null);
              }}
            />
          )}
        </SearchContainer>
      </TwoColumns>
    </Layout>
  );
};

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
