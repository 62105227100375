import { useEffect, useState } from "react";
import { Layout, useSignupContext } from "Components";
import { styled } from "styled-components";
import { Body1, H4, Label } from "melodies-source/Text";
import { Select } from "melodies-source/Select";
import { Modal } from "melodies-source/Modal";
import { TwoColumns } from "Components/TwoColumns";
import { Button } from "melodies-source/Button";
import { ReactComponent as HeadingIcon } from "assets/svg/your-affiliation.svg";
import { ReactComponent as ArrowRightIcon } from "assets/svg/arrow-right.svg";
import { TextInput } from "melodies-source/TextInput";
import { Terms } from "./ContactInfo";
import { AFFILIATION_OPTIONS } from ".";

export const YourAffiliation = () => {
  const { data, handleNext, handleBack, profile } = useSignupContext();
  const [selectedAffiliation, setSelectedAffiliation] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [companyName, setCompanyName] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [terms, setTerms] = useState<boolean>(false);

  useEffect(() => {
    if (profile?.affiliation) {
      setSelectedAffiliation(profile?.affiliation);
    }
    if (profile?.companyName) {
      setCompanyName(profile?.companyName);
    }
    if (profile?.title) {
      setTitle(profile?.title);
    }
  }, []);

  const hasName = !!profile?.name?.firstName && !!profile?.name?.lastName;

  const handleClick = () => {
    let submit = {
      affiliation: selectedAffiliation,
      companyName,
      title,
    };
    if (!hasName) {
      handleNext({
        ...submit,
        firstName,
        lastName,
      });
    } else {
      handleNext(submit);
    }
  };

  const valid = !hasName
    ? !!firstName && !!lastName && !!selectedAffiliation && terms
    : !!selectedAffiliation;

  return (
    <Layout
      cardProps={{
        size: "large",
        heading: { icon: <HeadingIcon />, text: "Your Affiliation" },
        actions: {
          link: "contact",
          buttons: (
            <>
              {data.steps.length > 1 && (
                <Button variant="outlined" onClick={() => handleBack()}>
                  Back
                </Button>
              )}
              <Button
                disabled={!valid}
                rightIcon={<ArrowRightIcon />}
                onClick={handleClick}
              >
                Next
              </Button>
            </>
          ),
        },
      }}
    >
      <Content>
        <div>
          <H4>
            What is your affiliation with{" "}
            {data?.artistName || data?.artistSearch || data?.artist?.label}?
          </H4>
          <Body1>
            With so many people potentially involved in the success of this
            artist, we want to make sure that your SET experience is tailored to
            your role and that we are granting access to the right person.
            <br />
            <br />
            <LinkText onClick={() => setModalIsOpen(true)}>
              Not sure which to choose?
            </LinkText>
          </Body1>
        </div>
        <Form>
          {!hasName && (
            <YourName>
              <Label>Your Name</Label>
              <div>
                <TextInput
                  placeholder="First Name"
                  value={firstName}
                  onChange={setFirstName}
                />
                <TextInput
                  placeholder="Last Name"
                  value={lastName}
                  onChange={setLastName}
                />
              </div>
            </YourName>
          )}
          <Select
            placeholder="Choose Affiliation..."
            value={selectedAffiliation}
            onChange={setSelectedAffiliation}
            label="Choose what best describes you:"
            options={AFFILIATION_OPTIONS}
          />
          {selectedAffiliation !== "artist" && (
            <>
              <TextInput
                label="Company Name"
                placeholder="Please enter your company name"
                value={companyName}
                onChange={setCompanyName}
              />
              <TextInput
                label="Your Title"
                placeholder="Please enter your title"
                value={title}
                onChange={setTitle}
              />
            </>
          )}
          {!hasName && <Terms value={terms} onChange={setTerms} />}
        </Form>
      </Content>
      <Modal
        isOpen={modalIsOpen}
        header="Artist Affiliations"
        onClose={() => setModalIsOpen(false)}
        variant="medium"
      >
        <ModalContent>
          <H4>Artist</H4>
          <Body1>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed
            ultricies lorem. Mauris imperdiet tellus quis maximus venenatis.
            Aliquam commodo quam vel volutpat venenatis. Nulla laoreet fringilla
            mauris, at egestas justo euismod vel.
          </Body1>
          <H4>Management Company</H4>
          <Body1>
            Aenean id felis vitae lectus dignissim gravida. Morbi placerat sem
            ut varius gravida. Curabitur ut laoreet risus, ut ultricies augue.
            Class aptent taciti sociosqu ad litora torquent per conubia nostra,
            per inceptos himenaeos.
          </Body1>
          <H4>Marketing/Consulting Firm</H4>
          <Body1>
            Mauris et nisl nulla. Aliquam ultrices metus a leo faucibus,
            malesuada porta neque accumsan. Nunc facilisis metus et lorem
            sollicitudin volutpat. Integer ut interdum velit.
          </Body1>
          <H4>Music Label</H4>
          <Body1>
            Pellentesque neque tellus, viverra eu purus non, tincidunt viverra
            magna. Nunc rhoncus eu mauris nec molestie. Vestibulum massa orci,
            hendrerit sed est ut, porta rutrum orci. Sed imperdiet suscipit
            eros, eget pharetra dolor faucibus tempus.
          </Body1>
        </ModalContent>
      </Modal>
    </Layout>
  );
};

const Content = styled(TwoColumns)`
  & > div {
    h4 {
      margin-bottom: 20px;
    }
  }
`;

const YourName = styled.div`
  & > div {
    display: flex;

    & > div:nth-child(1) input {
      border-radius: 6px 0 0 6px;
    }

    & > div:nth-child(2) input {
      border-radius: 0 6px 6px 0;
      border-left: none;
    }
  }
`;

const ModalContent = styled.div`
  ${H4} {
    margin-top: 20px;
  }
`;

const LinkText = styled.span`
  color: #00e;
  cursor: pointer;
  text-decoration: underline;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  label {
    margin-bottom: 5px !important;
  }
`;
