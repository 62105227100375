import { useEffect, useState } from "react";
import { CodeInput, Layout, functions, useSignupContext } from "Components";
import { styled } from "styled-components";
import { TwoColumns } from "Components/TwoColumns";
import { Body1, Body2, H4, Error } from "melodies-source/Text";
import { Button } from "melodies-source/Button";
import { ReactComponent as HeadingIcon } from "assets/svg/verification-sent.svg";
import { ReactComponent as PhoneConfirmation } from "assets/svg/phone-confirmation.svg";
import { useDesktopMediaQuery } from "hooks/useDesktopMediaQuery";
import { TextInput } from "melodies-source/TextInput";
import { ReactComponent as ArrowRightIcon } from "assets/svg/arrow-right.svg";
import { httpsCallable } from "firebase/functions";

export const VerifyPhone = () => {
  const { handleNext, active, data } = useSignupContext();
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const onboarding = httpsCallable<
    { action: string; phone: string; signupId: string; code?: string },
    { status: string; signupId: string }
  >(functions, "signup-account");
  const isDesktop = useDesktopMediaQuery();

  const sendVerifyText = () => {
    onboarding({
      action: "verify",
      signupId: active!.id,
      phone: data.tempphone,
    });
  };
  useEffect(() => {
    sendVerifyText();
  }, []);

  const handleVerify = () => {
    setLoading(true);
    setHasError(false);
    onboarding({
      action: "verify",
      signupId: active!.id,
      phone: data.tempphone,
      code,
    })
      .then((res) => {
        if (res.data.status === "code-confirmed") {
          handleNext();
        } else {
          setHasError(true);
        }
        return res;
      })
      .catch(() => {
        setHasError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Layout
      cardProps={{
        size: "large",
        heading: { icon: <HeadingIcon />, text: "Verification Sent" },
        actions: {
          link: "contact",
          buttons: (
            <>
              <Button variant="outlined" onClick={sendVerifyText}>
                Resend
              </Button>
              <Button
                disabled={loading || code.length !== 6}
                rightIcon={<ArrowRightIcon />}
                onClick={handleVerify}
                loading={loading}
              >
                Verify
              </Button>
            </>
          ),
        },
      }}
    >
      <Content>
        <div>
          <H4>We have texted a verification code to your phone.</H4>
          <Body1>
            Please check your text messages and enter the six-digit code from
            SET within the next 10 minutes to verify your phone number.
          </Body1>
          <div>
            <CodeInput
              value={code}
              onChange={setCode}
              size={6}
              error={false}
              allow={/\d/}
            />
            <Body2>Please enter your verification code here</Body2>
            {hasError && <Error>Verification Code Not Valid</Error>}
          </div>
        </div>
        {isDesktop && <PhoneConfirmation />}
      </Content>
    </Layout>
  );
};

const Content = styled(TwoColumns)`
  & > div {
    h4 {
      margin-bottom: 20px;
    }
  }

  ${Body1} {
    margin-bottom: 25px;
  }
`;
