import React from "react";
import { AppContainer } from "melodies-source/common";
import { styled } from "styled-components";
import { Footer } from "../Footer";
import { LayoutProps } from "..";
import { Card } from "./Card";
import BgImageSignup from "assets/images/background-signup.png";
import BgImageLogin from "assets/images/background-login.jpeg";
import BG1 from "./SET_BG1.jpg";
import BG2 from "./SET_BG2.jpg";
import BG3 from "./SET_BG3.jpg";
import BG4 from "./SET_BG4.jpg";
import MBG1 from "./SET_BG1mobile.jpg";
import MBG2 from "./SET_BG2mobile.jpg";
import MBG3 from "./SET_BG3mobile.jpg";
import MBG4 from "./SET_BG4mobile.jpg";

export const Content: React.FC<LayoutProps> = ({
  children,
  className,
  cardProps,
  flow,
}) => {
  return (
    <Background
      flow={flow}
      image={flow === "signup" ? BgImageSignup : BgImageLogin}
    >
      <Container {...(className && { className })}>
        <Card {...cardProps}>{children}</Card>
      </Container>
      <Footer flow={flow} />
    </Background>
  );
};

interface BackgroundProps {
  image?: string;
  flow: LayoutProps["flow"];
}

const BackgroundContainer = styled.div<BackgroundProps>`
  display: grid;
  position: relative;
  grid-template-rows: 1fr auto;
  height: 100%;
`;

const BackgroundInternal = styled.div<BackgroundProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: ${({ flow }) => (flow === "signup" ? "left" : "right")};
  @keyframes fade {
    0% {
      opacity: 1;
    }
    21.43% {
      opacity: 1;
    }
    25% {
      opacity: 0;
    }
    96.43% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: fade 140s infinite;
    &.mobile {
      display: none;
      ${({ theme }) => theme.media.mobile} {
        display: block;
      }
    }
    &:not(.mobile) {
      ${({ theme }) => theme.media.mobile} {
        display: none;
      }
    }
  }
  #bg1d {
    animation-delay: 0s;
    background: url(${BG1}) lightgray 50% / cover no-repeat;
  }
  #bg2d {
    animation-delay: 35s;
    background: url(${BG2}) lightgray 50% / cover no-repeat;
  }
  #bg3d {
    animation-delay: 70s;
    background: url(${BG3}) lightgray 50% / cover no-repeat;
  }
  #bg4d {
    animation-delay: 105s;
    background: url(${BG4}) lightgray 50% / cover no-repeat;
  }
  #bg1m {
    animation-delay: 0s;
    background: url(${MBG1}) lightgray 50% / cover no-repeat;
  }
  #bg2m {
    animation-delay: 35s;
    background: url(${MBG2}) lightgray 50% / cover no-repeat;
  }
  #bg3m {
    animation-delay: 70s;
    background: url(${MBG3}) lightgray 50% / cover no-repeat;
  }
  #bg4m {
    animation-delay: 105s;
    background: url(${MBG4}) lightgray 50% / cover no-repeat;
  }
`;

const Background = ({ children, flow, ...props }: any) => {
  return (
    <BackgroundContainer {...props}>
      <BackgroundInternal flow={flow}>
        <div id="bg1d" className="bg-image" />
        <div id="bg2d" className="bg-image" />
        <div id="bg3d" className="bg-image" />
        <div id="bg4d" className="bg-image" />
        <div id="bg1m" className="bg-image mobile" />
        <div id="bg2m" className="bg-image mobile" />
        <div id="bg3m" className="bg-image mobile" />
        <div id="bg4m" className="bg-image mobile" />
      </BackgroundInternal>
      {children}
    </BackgroundContainer>
  );
};

const Container = styled(AppContainer)`
  align-items: center;
  padding: 25px;
  z-index: 1;
`;
