import { Layout } from "Components";
import { Body1, H1, H2 } from "melodies-source/Text";
import { Button } from "melodies-source/Button";
import { useDesktopMediaQuery } from "hooks/useDesktopMediaQuery";
import { ReactComponent as CheckIcon } from "assets/svg/check-circle-green.svg";
import { styled } from "styled-components";

export const PhoneConfirmed = () => {
  const isDesktop = useDesktopMediaQuery();
  const Heading = isDesktop ? H1 : H2;

  return (
    <Layout>
      <Container>
        <CheckIcon width={isDesktop ? 70 : 60} />
        <Heading>Phone Confirmed</Heading>
        <Body1>
          Your final step will be to create a password for your artist account.
        </Body1>
        <ContinueButton>Continue to Password</ContinueButton>
      </Container>
    </Layout>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  ${Body1} {
    text-align: center;
  }
`;

const ContinueButton = styled(Button)`
  margin: 30px 0 10px 0;

  ${({ theme }) => theme.media.mobile} {
    margin-top: 20px;
  }
`;
