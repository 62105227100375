import { useEffect, useState } from "react";
import { styled } from "styled-components";
import { Layout, useSignupContext } from "Components";
import { Button } from "melodies-source/Button";
import { Body2, H4 } from "melodies-source/Text";
import { TextInput } from "melodies-source/TextInput";
import { ReactComponent as Logo } from "assets/svg/logo.svg";
import { ReactComponent as KeyIcon } from "assets/svg/key.svg";

const PASSWORD_REGEX =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

export const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordIsValid, setPasswordIsValid] = useState(true);
  const [passwordIsSame, setPasswordIsSame] = useState(true);
  const { handleNext } = useSignupContext();

  const validatePassword = () => {
    let valid = true;
    if (!PASSWORD_REGEX.test(password)) {
      setPasswordIsValid(false);
      valid = false;
    }
    if (password !== passwordConfirm) {
      setPasswordIsSame(false);
      valid = false;
    }

    if (valid) handleNext();
  };

  useEffect(() => {
    if (!passwordIsValid) setPasswordIsValid(true);
    if (!passwordIsSame) setPasswordIsSame(true);
  }, [password]);

  useEffect(() => {
    if (!passwordIsSame) setPasswordIsSame(true);
  }, [passwordConfirm]);

  return (
    <Layout flow="login">
      <Container>
        <StyledLogo />
        <H4>Reset Password</H4>
        <TextInput
          value={password}
          onChange={setPassword}
          label="New Password"
          placeholder="Enter a new password..."
          type="password"
          leftIcon={<KeyIcon />}
          {...(!passwordIsValid && {
            hasError: true,
            helperText: "Please enter a valid password",
          })}
        />
        <TextInput
          label="Confirm Password"
          value={passwordConfirm}
          onChange={setPasswordConfirm}
          type="password"
          placeholder="Passwords must match..."
          leftIcon={<KeyIcon />}
          {...(!passwordIsSame && {
            hasError: true,
            helperText: "Passwords don't match",
          })}
        />
        <Body2>
          Password must be at least 8 characters, contain at least one special
          character, one letter, and one number
        </Body2>
        <Buttons>
          <Button disabled={!password} onClick={validatePassword}>
            Reset Password
          </Button>
          <Button text>Back</Button>
        </Buttons>
      </Container>
    </Layout>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  ${Body2} {
    text-align: center;
  }
`;

const StyledLogo = styled(Logo)`
  width: 150px;
  height: 66px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-top: 10px;

  button {
    width: 100%;
  }
`;
