import { CreateAuthentication } from "@musicaudienceexchange/firebase-auth-ui";
import { firebaseApp } from "Components";

export const { useUser, UserContext, UserProvider } = CreateAuthentication({
  firebaseApp,
  fallbackToAnon: true,
  loadClaims: (data) => ({
    isAdmin: typeof data.admin === "boolean" && data.admin === true,
  }),
});
