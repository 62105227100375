import { createContext, useContext, useState } from "react";
import { ContactUs } from "./ContactUs";

interface ContactContextProps {
  showContact: boolean;
  setShowContact: (show: boolean) => void;
}
//@ts-ignore
export const ContactContext = createContext<ContactContextProps>(undefined);

//@ts-ignore
export const ContactProvider = ({ children }) => {
  const [showContact, setShowContact] = useState<boolean>(false);

  return (
    <ContactContext.Provider value={{ showContact, setShowContact }}>
      {children}
      <ContactUs open={showContact} setOpen={setShowContact} />
    </ContactContext.Provider>
  );
};

export const useContactContext = () => useContext(ContactContext);
