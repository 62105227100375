import { useEffect, useState } from "react";
import { styled } from "styled-components";
import {
  Layout,
  TwoColumns,
  useContactContext,
  useSignupContext,
} from "Components";
import { Body1, Body2, H2, H4 } from "melodies-source/Text";
import { TextInput } from "melodies-source/TextInput";
import { Button } from "melodies-source/Button";
import { ReactComponent as EmailIcon } from "assets/svg/email-outlined.svg";
import { ReactComponent as ArrowRightIcon } from "assets/svg/arrow-right.svg";

const EMAIL_REGEX = /^(.+)@(.+)$/;

export const Email = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { handleStart } = useSignupContext();
  const [email, setEmail] = useState<string>("");
  const [emailIsValid, setEmailIsValid] = useState(true);
  const { setShowContact } = useContactContext();

  const validateEmail = () => {
    const valid = EMAIL_REGEX.test(email);
    if (valid) {
      setLoading(true);
      handleStart({ email }).finally(() => setLoading(false));
    } else {
      setEmailIsValid(false);
    }
  };

  useEffect(() => {
    if (!emailIsValid) setEmailIsValid(true);
  }, [email]);

  return (
    <Layout
      cardProps={{
        welcome: true,
        actions: {
          link: "login",
          buttons: (
            <>
              <Button variant="tertiary">Cancel</Button>
              <Button
                disabled={loading || !email}
                onClick={validateEmail}
                rightIcon={<ArrowRightIcon />}
                loading={loading}
              >
                Next
              </Button>
            </>
          ),
        },
      }}
    >
      <TwoColumns gap="35px">
        <div>
          <Body1>
            Welcome to SET: the platform that lets you capture and own your fan
            info at live shows and on social media.
            <br />
            <br /> We will guide you through the set-up process to create an
            account. If at any point you have a question or issue,{" "}
            <a href="#contact" onClick={() => setShowContact(true)}>
              Contact MAX
            </a>
            .
          </Body1>
        </div>
        <EmailContainer>
          <H4>What email do you want to use for this account?</H4>
          <TextInput
            value={email}
            onChange={(email) => setEmail(email.trim())}
            label="Email"
            placeholder="Please enter your email"
            leftIcon={<EmailIcon />}
            {...(!emailIsValid && {
              hasError: true,
              helperText: "Please enter a valid email",
            })}
          />
          <Body2>
            This is typically the email used in connection with the artist and
            will serve as your login credentials.
          </Body2>
        </EmailContainer>
      </TwoColumns>
    </Layout>
  );
};

const EmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
  }
`;
