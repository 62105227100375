import React, { createContext, useContext, useEffect, useState } from "react";
import { ImageInputProps } from ".";

type Props = {
  aspectRatio: number;
  imageHeightPx: number;
} & Omit<ImageInputProps, "aspectRatio" | "imageHeightPx">;

type Step = "select" | "crop" | "upload";

type ImageUrl = string | null;
type State = {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onCloseModal: () => void;
  step: Step;
  setStep: React.Dispatch<React.SetStateAction<Step>>;
  imageUrl: ImageUrl;
  setImageUrl: React.Dispatch<React.SetStateAction<ImageUrl>>;
} & Props;
const ImageInputContext = createContext<State>({} as State);

export const ImageInputContextProvider = ({
  children,
  onUrlChange,
  ...props
}: {
  children: JSX.Element;
} & Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [step, setStep] = useState<Step>("select");
  const [imageUrl, setImageUrl] = useState<ImageUrl>(null);

  useEffect(() => {
    if (onUrlChange) {
      onUrlChange(imageUrl ?? "");
    }
  }, [imageUrl]);

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  const value = {
    ...props,
    isModalOpen,
    setIsModalOpen,
    onCloseModal,
    step,
    setStep,
    imageUrl,
    setImageUrl,
  };
  return (
    <ImageInputContext.Provider value={value}>
      {children}
    </ImageInputContext.Provider>
  );
};

export const useImageInputContext = () => useContext(ImageInputContext);
