import React from "react";
import { styled } from "styled-components";

interface Props {
  children: React.ReactNode;
  className?: string;
  gap?: string;
}
export const TwoColumns: React.FC<Props> = ({
  children,
  className,
  gap = "50px",
}) => {
  return (
    <Container className={className} gap={gap}>
      {children}
    </Container>
  );
};

type ContainerProps = Pick<Props, "gap">;

const Container = styled.div<ContainerProps>`
  display: flex;
  gap: ${({ gap }) => gap};

  & > * {
    width: 50%;
  }

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    gap: 15px;

    & > * {
      width: 100%;
      margin-bottom: 20px;
    }
  }
`;
