import React from "react";
import { Body2, H4 } from "melodies-source/Text";
import { css, styled } from "styled-components";
import { ReactComponent as CloseIcon } from "assets/svg/close-filled.svg";

interface Artist {
  image: string;
  name: string;
  genre: string;
}

export type ArtistCompact = Artist;

interface Props {
  artist: Artist;
  onClose?: () => void;
  isSelected?: boolean;
  isOption?: boolean;
  onSelect?: (artist: Artist) => void;
}

export const AritistInfoCompact: React.FC<Props> = ({
  artist,
  onClose,
  isSelected,
  isOption,
  onSelect,
}) => {
  return (
    <Container
      isSelected={isSelected}
      isOption={isOption}
      onSelect={onSelect}
      {...(onSelect && { onClick: () => onSelect(artist) })}
    >
      <img src={artist.image} alt={artist.name} />
      <div>
        <H4>{artist.name}</H4>
        <Body2>{artist.genre}</Body2>
      </div>
      {onClose && <StyledCloseIcon onClick={onClose} />}
    </Container>
  );
};

type ContainerProps = Pick<Props, "isSelected" | "isOption" | "onSelect">;

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 15px;
  border-radius: 6px;
  color: #333;
  border: 1px solid #ccc;

  ${({ isOption }) =>
    isOption &&
    css`
      border: none;
      padding: 5px 10px;

      &:hover {
        background: #f7fafc;
      }
    `}

  ${({ isSelected }) =>
    isSelected &&
    css`
      background: #f7fafc;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    `}


  ${({ onSelect }) =>
    onSelect &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }

  ${Body2} {
    text-transform: capitalize;
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  margin-left: auto;
  color: #666666;

  &:hover {
    cursor: pointer;
  }
`;
