import styled, { css } from "styled-components";

export * from "./layout";

export const IconWrapper = styled.div(
  ({ theme }) => css`
    height: 32px;
    width: 32px;
    border-radius: 100%;
    user-select: none;
    margin: 0 auto;
    color: #666666;
    :hover {
      background: #f2f5f7;
      cursor: pointer;
    }
  `
);
