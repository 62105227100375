import { createContext, useEffect, useContext } from "react";
import LogRocket from "logrocket";
import { useUser } from "auth";
import { User } from "firebase/auth";
import { VerificationLinkExpired } from "Routes/Signup/Components";

interface AuthContextProps {
  user: User;
}
//@ts-ignore
export const AuthContext = createContext<AuthContextProps>(undefined);

//@ts-ignore
export const AuthProvider = ({ children }) => {
  //@ts-ignore
  const { user, hasExpiredSignInWithEmailLink } = useUser();

  console.log("user", user);

  useEffect(() => {
    if (process.env.REACT_APP_ENV !== "dev") {
      if (user?.uid) {
        LogRocket.identify(user.uid, {
          name: user.displayName as string,
          email: user.email as string,
          phone: user.phoneNumber as string,
        });
      }
    }
  }, [user]);

  if (hasExpiredSignInWithEmailLink) {
    return <VerificationLinkExpired />;
  }

  if (!user) {
    return null;
  }

  return (
    <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
