import { useState } from "react";
import { Layout, useSignupContext } from "Components";
import { Body1, H1, H2, Label } from "melodies-source/Text";
import { CountryPhone, CountryPhoneState } from "melodies-source/CountryPhone";
import { ReactComponent as CheckIcon } from "assets/svg/check-circle-green.svg";
import { styled } from "styled-components";
import { Button } from "melodies-source/Button";
import { useDesktopMediaQuery } from "hooks/useDesktopMediaQuery";

export const EnterPhone = () => {
  const { data, size, handleNext } = useSignupContext();
  const [phone, setPhone] = useState("");
  const [phoneState, setPhoneState] = useState<CountryPhoneState>();
  const isDesktop = useDesktopMediaQuery();

  const handlePhoneChange = (state: CountryPhoneState) => {
    const { value } = state;
    setPhone(value);
    setPhoneState(state);
  };

  const handleClick = () => {
    handleNext({
      tempphone: phone,
    });
  };

  const Heading = isDesktop ? H1 : H2; //imo we should do this via media queries on the header component

  return (
    <Layout>
      <Container>
        <CheckIcon width={isDesktop ? 70 : 60} />
        <Heading>Email Confirmed</Heading>
        {["large", "medium"].includes(size) ? (
          <>
            <Body1>
              For additional security, we need to confirm your phone number.
              Please enter the number you want to use for this account:
            </Body1>
            <PhoneContainer>
              <Label>Phone Number</Label>
              <CountryPhone value={phone} onChange={handlePhoneChange} />
            </PhoneContainer>
            <StyledButton onClick={handleClick} disabled={!phoneState?.isValid}>
              Confirm Phone
            </StyledButton>
          </>
        ) : (
          <>
            <Body1>
              Your final step will be to create a password for your artist
              account.
            </Body1>
            <StyledButton onClick={() => handleNext()}>
              Continue to Password
            </StyledButton>
          </>
        )}
      </Container>
    </Layout>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  ${Body1} {
    text-align: center;
  }
`;

const PhoneContainer = styled.div`
  margin-top: 10px;

  ${Label} {
    margin-bottom: 5px;
  }
`;

const StyledButton = styled(Button)`
  margin: 30px 0 10px 0;

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
  }
`;
