import { Layout, functions } from "Components";
import { Button } from "melodies-source/Button";
import { Body1, H4 } from "melodies-source/Text";
import { ReactComponent as Logo } from "assets/svg/logo.svg";
import { styled } from "styled-components";
import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import { addToast } from "melodies-source/Toast";

export const VerificationLinkExpired = () => {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const onboarding = httpsCallable<
    { action: string; signupId: string },
    { status: string; signupId: string }
  >(functions, "signup-account");

  const queryParams = new URLSearchParams(window.location.search);

  const enableButton = () => {
    setTimeout(() => {
      setDisabled(false);
    }, 30000);
  };

  const sendEmail = () => {
    setLoading(true);
    onboarding({
      action: "send-access-email",
      signupId: queryParams.get("signup") as string,
    })
      .then((res) => {
        if (res?.data?.status === "ok") {
          addToast(`Verification link sent`, "success", {
            position: "top-right",
            duration: 7000,
          });
        } else {
          addToast(`An error occurred`, "error", {
            position: "top-right",
            duration: 7000,
          });
        }
      })
      .finally(() => {
        setDisabled(true);
        setLoading(false);
        enableButton();
      });
  };

  return (
    <Layout flow="login">
      <Container>
        <Logo />
        <H4>Your verification link has expired</H4>
        <Body1>
          For your account security, our verification links expire after 24
          hours.
          <br /> You can request a new link below to the email we have on file.
        </Body1>
        <Button onClick={sendEmail} loading={loading} disabled={disabled}>
          Resend Verification Email
        </Button>
      </Container>
    </Layout>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  text-align: center;
  width: 100%;

  svg {
    width: 150px;
    height: 66px;
  }

  button {
    width: 100%;
  }
`;
