import { useState } from "react";
import { Layout, useSignupContext } from "Components";
import { styled } from "styled-components";
import { TwoColumns } from "Components/TwoColumns";
import { Body1, Body2, H4, Label } from "melodies-source/Text";
import { TextInput } from "melodies-source/TextInput";
import { Checkbox } from "melodies-source/Selectable";
import { Button } from "melodies-source/Button";
import { ReactComponent as HeadingIcon } from "assets/svg/contact-info.svg";
import { ReactComponent as ArrowRightIcon } from "assets/svg/arrow-right.svg";

export const ContactInfo = () => {
  const { handleNext, handleBack, data } = useSignupContext();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [title, setTitle] = useState("");
  const [agreeTerms, setAgreeTerms] = useState(false);

  const formIsValid =
    data.affiliation === "artist"
      ? firstName && lastName && agreeTerms
      : firstName && lastName && companyName && title && agreeTerms;
  return (
    <Layout
      cardProps={{
        size: "large",
        heading: { icon: <HeadingIcon />, text: "Contact Info" },
        actions: {
          link: "contact",
          buttons: (
            <>
              <Button variant="outlined" onClick={() => handleBack()}>
                Back
              </Button>
              <Button
                disabled={!formIsValid}
                onClick={() =>
                  handleNext({
                    firstName,
                    lastName,
                    companyName,
                    title,
                  })
                }
                rightIcon={<ArrowRightIcon />}
              >
                {data.action === "request-access" ? "Request Access" : "Next"}
              </Button>
            </>
          ),
        },
      }}
    >
      <Content>
        <div>
          <H4>Please enter your contact information.</H4>
          <Body1>
            Make sure you enter the email associated with the team you want to
            create. <br />
            <br />
            Your email address will also be your username.
          </Body1>
        </div>
        <Form>
          <YourName>
            <Label>Your Name</Label>
            <div>
              <TextInput
                placeholder="First Name"
                value={firstName}
                onChange={setFirstName}
              />
              <TextInput
                placeholder="Last Name"
                value={lastName}
                onChange={setLastName}
              />
            </div>
          </YourName>
          {data.affiliation !== "artist" && (
            <>
              <TextInput
                label="Company Name"
                placeholder="Please enter your company name"
                value={companyName}
                onChange={setCompanyName}
              />
              <TextInput
                label="Your Title"
                placeholder="Please enter your title"
                value={title}
                onChange={setTitle}
              />
            </>
          )}
          <div>
            <Label>Your Email</Label>
            <Email>{data.email}</Email>
          </div>
          <Terms value={agreeTerms} onChange={setAgreeTerms} />
        </Form>
      </Content>
    </Layout>
  );
};

export const Terms = ({
  value,
  onChange,
}: {
  value: boolean;
  onChange: (value: boolean) => void;
}) => (
  <TermsCheckbox
    label={
      <Body2>
        By checking this box, I agree to the{" "}
        <a
          href={`${process.env.REACT_APP_SL_URL}/artist-terms`}
          target="_blank"
          rel="noreferrer"
          onClick={(e) => e.stopPropagation()}
        >
          terms and conditions
        </a>{" "}
        of using SET as well as the SET{" "}
        <a
          href={`${process.env.REACT_APP_SL_URL}/privacy`}
          target="_blank"
          rel="noreferrer"
          onClick={(e) => e.stopPropagation()}
        >
          privacy policy.
        </a>
      </Body2>
    }
    value={value}
    onChange={onChange}
  />
);

const Content = styled(TwoColumns)`
  & > div {
    h4 {
      margin-bottom: 20px;
    }
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${Label} {
    //color: #4c4c4c;
    margin-bottom: 5px;
  }
`;

const YourName = styled.div`
  & > div {
    display: flex;

    & > div:nth-child(1) input {
      border-radius: 6px 0 0 6px;
    }

    & > div:nth-child(2) input {
      border-radius: 0 6px 6px 0;
      border-left: none;
    }
  }
`;

const Email = styled(Body2)`
  font-size: 14px;
  color: #999999;
`;

const TermsCheckbox = styled(Checkbox)`
  margin-top: 15px;
`;
