// @ts-nocheck
import React, { CSSProperties, useEffect } from "react";
import { useRef, useState } from "react";
import ReactCrop, { Crop, centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useImageInputContext } from "../ImageInputContextProvider";
import { styled } from "styled-components";

type Props = {
  srcImg: string;
  options?: Crop;
  onCrop: (blob: Blob | null) => void;
  Wrapper: React.FC;
  style?: CSSProperties | undefined;
};
const ImageCrop = ({
  srcImg,
  options = {},
  onCrop,
  Wrapper = div,
  style = {},
}: Props) => {
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [crop, setCrop] = useState<Crop>(options);

  const { aspectRatio, setStep } = useImageInputContext();

  const getCroppedImg = (image, crop) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      }, "image/jpeg");
    });
  };

  const makeCrop = async (crop) => {
    if (imageRef && crop.width && crop.height) {
      const croppedImage = await getCroppedImg(imageRef.current, crop);
      onCrop(croppedImage);
    }
  };

  const handleImageLoad = (
    e: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    imageRef.current = e.currentTarget;

    const { naturalWidth, naturalHeight, width, height } = e.currentTarget;

    let crop = centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90,
        },
        aspectRatio,
        naturalWidth,
        naturalHeight
      ),
      naturalWidth,
      naturalHeight
    );

    crop = {
      unit: "px",
      x: (crop.x / 100) * width,
      y: (crop.y / 100) * height,
      width: (crop.width / 100) * width,
      height: (crop.height / 100) * height,
    };

    makeCrop(crop);
    setCrop(crop);
  };

  useEffect(() => {
    if (imageRef.current) setStep("crop");
  }, [imageRef.current]);
  return (
    <>
      {imageRef.current && (
        <p style={{ marginBottom: 20, color: "black" }}>
          Click, drag, or and scale the template mask over your image.
        </p>
      )}
      <Wrapper>
        <ReactCrop
          crop={crop}
          onChange={(c) => setCrop(c)}
          onComplete={(c) => makeCrop(c)}
          style={style}
          imageStyle={style}
          aspect={aspectRatio}
        >
          <StyledImg src={srcImg} onLoad={handleImageLoad} alt="crop" />
        </ReactCrop>
      </Wrapper>
    </>
  );
};

export default ImageCrop;

const StyledImg = styled.img`
  max-height: 30vh !important;
`;
