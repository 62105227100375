import React from "react";
import { styled } from "styled-components";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "assets/svg/logo.svg";

export const Menu: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <StyledLogo onClick={() => navigate("/")} />{" "}
    </Container>
  );
};

const Container = styled.div`
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: inset 0 -1px 0 0 #e6e9eb;
  background: white;

  ${({ theme }) => theme.media.mobile} {
    height: 50px;
    padding: 12px 0px;
  }
`;

const StyledLogo = styled(Logo)`
  cursor: pointer;

  ${({ theme }) => theme.media.mobile} {
    height: 25px;
  }
`;
