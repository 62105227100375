import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Caption, Label, Body2 } from "../Text";
import { SvgHide } from "../Svgs/Hide";
import { SvgShow } from "../Svgs/Show";
import { SvgEdit } from "../Svgs/Edit";
import { SvgDelete } from "../Svgs/Delete";

const InputIcon = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
`;

const RightIcon = styled(InputIcon)`
  color: #666666;
  right: 12px;
`;

const LeftIcon = styled(InputIcon)`
  color: #999999;
  left: 12px;
`;

const styleSecondaryInputDefault = css`
  background: transparent;
  border-radius: 0px;
  padding: 6px 12px 5px 0;
  color: #ffffff;
  border: none;
  border-bottom: 1px solid #666666;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  transition: ${(p) => p.theme.transitions("border-color").hover};

  ::placeholder {
    color: #999999;
    font-family: var(--max-font-primary);
    font-weight: 300;
    font-size: 18px;
  }
  &:focus {
    border-bottom-color: #ffffff;
  }
`;

const styleSecondaryInputDisabled = css`
  background: transparent;
  border-bottom-color: rgba(255, 255, 255, 0.25);
`;

export const HTMLInput = styled.input`
  outline: none;
  border: none;
  width: 100%;
  box-sizing: border-box;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  -moz-appearance: textfield;

  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 6px;
  padding: 11px;
  font-family: var(--max-font-primary);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #000000;
  text-overflow: ellipsis;

  ::placeholder {
    color: #999999;
    font-family: var(--max-font-primary);
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
  }

  ${(p: any) => p.secondary && styleSecondaryInputDefault};
  ${(p: any) =>
    p.disabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      color: #999999;
      border: solid 1px #e6e9eb;
    `};

  ${(p: any) => p.secondary && p.disabled && styleSecondaryInputDisabled};
  ${(p: any) =>
    p.hasError &&
    css`
      border: solid 1px #ff0000;
    `};
  ${(p: any) =>
    p.hasError &&
    p.secondary &&
    css`
      border: none;
      border-bottom: solid 1px #ff0000;
    `};
  ${(p: any) =>
    p.hasRightIcon &&
    css`
      padding-right: 44px;
    `};
  ${(p: any) =>
    p.hasLeftIcon &&
    css`
      padding-left: 44px;
    `};
  ${(p: any) =>
    p.hasLeftIcon &&
    p.secondary &&
    css`
      padding-left: 32px;
    `};
`;

type InputWrapProps = {
  secondary?: boolean;
  disabled?: boolean;
};

export const InputWrap = styled.div<InputWrapProps>`
  width: 100%;

  ${Label} {
    color: #333333;
    margin-bottom: 10px;
  }
  ${(p: any) =>
    p.secondary &&
    css`
      ${Label} {
        font-size: 14px;
        line-height: 20px;
        color: #ffffff80;
        margin-bottom: 0px;
        font-weight: 500;
        ${p.disabled &&
        css`
          color: rgba(255, 255, 255, 0.35);
        `}
      }
      ${LeftIcon} {
        left: 0;
        svg {
          color: #ffffff80;
        }
      }
    `};
`;

export type TextInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "onChange"
> & {
  label?: string;
  rightIcon?: React.ReactNode;
  leftIcon?: React.ReactNode;
  placeholder?: string;
  value?: string;
  type?: string;
  secondary?: boolean;
  onChange: (v: string) => void;
  onBlur?: () => void;
  disabled?: boolean;
  hasError?: boolean;
  onFocus?: () => void;
  helperText?: string;
  style?: any;
};

export const TextInput = ({
  label = "",
  leftIcon,
  rightIcon,
  placeholder = "",
  value = "",
  secondary = false,
  type = "text",
  disabled = false,
  hasError = false,
  helperText,
  onChange,
  onBlur,
  onFocus,
  ...props
}: TextInputProps) => {
  const { passwordVisibleIcon, isPasswordVisible } =
    usePasswordVisibleIcon(type);
  const _rightIcon = passwordVisibleIcon || rightIcon;

  return (
    <InputWrap {...props} secondary={secondary} disabled={disabled}>
      {label && <Label>{label}</Label>}
      <div style={{ position: "relative", marginTop: label ? 4 : 0 }}>
        <LeftIcon
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {leftIcon}
        </LeftIcon>
        <HTMLInput
          {...{
            hasRightIcon: _rightIcon,
            hasLeftIcon: leftIcon,
            placeholder,
            secondary,
            disabled,
            onFocus,
            hasError,
            value,
          }}
          type={isPasswordVisible ? "text" : type}
          onChange={(e) => !disabled && onChange(e.target.value)}
          onBlur={() => onBlur && onBlur()}
        />
        <RightIcon
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {_rightIcon}
        </RightIcon>
      </div>
      {helperText && (
        <Caption
          style={{
            marginTop: "4px",
            color: `${hasError ? "#FF0000" : "#ffffff"}`,
          }}
        >
          {helperText}
        </Caption>
      )}
    </InputWrap>
  );
};

const PIcon = styled.div`
  text-align: center;
`;
const usePasswordVisibleIcon = (type: string) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  if (type !== "password")
    return { passwordVisibleIcon: null, isPasswordVisible: null };

  const passwordVisibleIcon = isPasswordVisible ? (
    <PIcon
      title="Hide"
      style={{ marginTop: "-2px" }}
      onClick={() => setIsPasswordVisible(false)}
    >
      <SvgHide />
    </PIcon>
  ) : (
    <PIcon title="Show" onClick={() => setIsPasswordVisible(true)}>
      <SvgShow />
    </PIcon>
  );
  return { isPasswordVisible, passwordVisibleIcon };
};

type EditInputProps = {
  onEdit?: () => void;
  onDelete?: () => void;
  value: JSX.Element | string;
  placeholder?: string;
  headline?: boolean;
  edit?: boolean;
};
export const InputEdit = ({
  onEdit,
  onDelete,
  edit,
  placeholder,
  value,
  headline,
}: EditInputProps) => {
  return (
    <InputEditWrap>
      {value || placeholder}
      <SvgEdit onClick={onEdit} />
      {!edit && <SvgDelete onClick={onDelete} />}
    </InputEditWrap>
  );
};

const InputEditWrap = styled.div`
  display: flex;
  color: #ffffff;
  gap: 10px;
  align-items: center;

  ${Body2} {
    color: #c9c6d1;
    margin-bottom: -5px;
  }

  svg {
    cursor: pointer;
    max-width: 20px;
  }
`;
