import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Button as ButtonBase } from "../Button";
import { SvgImageLarge } from "../Svgs/ImageLarge";
import { Caption, Selected } from "../Text";
import {
  ImageInputContextProvider,
  useImageInputContext,
} from "./ImageInputContextProvider";
import SelectImageModal from "./SelectImageModal";
import { ReactComponent as RefreshIcon } from "assets/svg/refresh.svg";
import { ReactComponent as UploadIcon } from "assets/svg/upload.svg";

const Options = styled.div`
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition: all ease 0.2s;
`;

type ImgContainerProps = {
  src?: string | null;
};
const ImgContainer = styled.div<ImgContainerProps>`
  box-sizing: border-box;
  border: 1px solid #dcdfe0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
  :hover {
    cursor: pointer;
    ${Options} {
      visibility: visible;
      opacity: 1;
    }
  }
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
`;

const MediaIcon = styled(SvgImageLarge)`
  width: 36px;
  height: 36px;
  path {
    fill: #999999;
  }
  ${(props) => props.theme.mediaQueries.mobile} {
    width: 22px;
    height: 22px;
  }
`;

const ButtonWrapper = styled.div`
  width: 70%;
  margin: 0 auto;
`;

const Button = styled(ButtonBase)`
  color: #f2f5f7;
`;

export type ImageInputProps = {
  label: string;
  value: Blob | null;
  style?: any;
  onSelect: (v: Blob) => void;
  description?: string;
  dropzoneDescription?: string | React.ReactNode;
  aspectRatio?: number;
  imageHeightPx?: number;
  openModalText?: string;
  onUrlChange?: (url: string) => void;
};

export const ImageInput = ({
  aspectRatio = 1,
  description,
  dropzoneDescription,
  label,
  imageHeightPx = 188,
  value,
  openModalText,
  onSelect,
  ...props
}: ImageInputProps) => {
  return (
    <div {...props}>
      <ImageInputContextProvider
        {...{
          aspectRatio,
          imageHeightPx,
          description,
          dropzoneDescription,
          label,
          value,
          onSelect,
          ...props,
        }}
      >
        <ImageInputWithContext openModalText={openModalText} />
      </ImageInputContextProvider>
      {!openModalText && <Caption style={{ marginTop: 8 }}>{label}</Caption>}
    </div>
  );
};

type ImageInputWithContextProps = Pick<ImageInputProps, "openModalText">;
export const ImageInputWithContext = ({
  openModalText,
}: ImageInputWithContextProps) => {
  const { setIsModalOpen, imageUrl } = useImageInputContext();

  return (
    <>
      <SelectImageModal />
      {imageUrl && (
        <img
          src={imageUrl}
          alt="crop"
          style={{ width: 120, height: 120, borderRadius: 6, marginBottom: 10 }}
        />
      )}

      <ImageInputPlaceholder onClick={() => setIsModalOpen(true)}>
        {!imageUrl ? (
          <>
            <UploadIcon />
            <span>{openModalText ?? "Upload Image"}</span>
          </>
        ) : (
          <>
            <RefreshIcon />
            <span>Replace</span>
          </>
        )}
      </ImageInputPlaceholder>
    </>
  );
};

const ImageInputPlaceholder = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  font-weight: 600;
  color: #1b0076;
  cursor: pointer;
`;
