import { useState } from "react";
import { styled } from "styled-components";
import { Layout } from "Components";
import { Button } from "melodies-source/Button";
import { TextInput } from "melodies-source/TextInput";
import { Subtitle1 } from "melodies-source/Text";
import { ReactComponent as Logo } from "assets/svg/logo.svg";
import { ReactComponent as EmailIcon } from "assets/svg/email-outlined.svg";
import { ReactComponent as KeyIcon } from "assets/svg/key.svg";

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <Layout flow="login">
      <Container>
        <StyledLogo />
        <TextInput
          value={email}
          onChange={setEmail}
          label="Email"
          placeholder="Email..."
          leftIcon={<EmailIcon />}
        />
        <PasswordContainer>
          <TextInput
            value={password}
            onChange={setPassword}
            label="Password"
            placeholder="Password..."
            type="password"
            leftIcon={<KeyIcon />}
          />
          <ForgortPasswordButton text>Forgot Password?</ForgortPasswordButton>
        </PasswordContainer>
        <Buttons>
          <Button>Login</Button>
          <Button text>Use a One-Time Login Link</Button>
        </Buttons>
      </Container>
    </Layout>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  ${Subtitle1} {
    color: #1b0076;
  }
`;

const StyledLogo = styled(Logo)`
  width: 150px;
  height: 66px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-top: 10px;

  button {
    width: 100%;
  }
`;

const PasswordContainer = styled.div`
  width: 100%;
`;

const ForgortPasswordButton = styled(Button)`
  margin-left: auto;
  padding: 0;
  font-size: 14px;
`;
