import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Modal as ModalBase, ModalContainer, ModalWrapper } from "../../Modal";
import ImageCrop from "./ImageCrop";
import ImageDropzone from "./ImageDropzone";
import { Button } from "../../Button";
import { Body1 } from "../../Text";
import { useImageInputContext } from "../ImageInputContextProvider";
import { Uploading } from "./Uploading";

const ImageWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  border: 1px solid #e6e9eb;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Modal = styled(ModalBase)`
  ${ModalContainer} {
    margin-top: 7vh;
  }

  h2,
  span,
  label {
    color: #333;
  }

  ${ModalWrapper} {
    background: white;
    ${(p) => p.theme.mediaQueries.desktop} {
      width: 600px;
    }
  }
`;

type MobileButtonProps = {
  disabled?: boolean;
};
const MobileButton = styled.div<MobileButtonProps>`
  color: #3795bd;
  font-weight: bold;
  user-select: none;
  margin: 0 auto;
  ${(p) =>
    p.disabled
      ? css`
          pointer-events: none;
          opacity: 0.5;
        `
      : css`
          :hover {
            cursor: pointer;
            opacity: 0.8;
          }
        `};
  ${(p) => p.theme.mediaQueries.desktop} {
    display: none;
  }
`;

const SelectImageModal: React.FC = () => {
  const {
    isModalOpen: isOpen,
    onCloseModal,
    label,
    step,
    setStep,
  } = useImageInputContext();

  let header = "";

  switch (step) {
    case "select": {
      header = label ?? "Upload Image";
      break;
    }
    case "crop": {
      header = "Crop Image";
      break;
    }
    case "upload": {
      header = "Uploading";
      break;
    }
  }

  useEffect(() => {
    setStep("select");
  }, [isOpen]);

  return (
    <Modal
      header={header}
      isOpen={isOpen}
      withCloseIcon={true}
      onClose={onCloseModal}
      withBackdropClose={false}
    >
      <ModalContent />
    </Modal>
  );
};

const ModalContent = () => {
  const [image, setImage] = useState(null);
  const [croppedImg, setCroppedImg] = useState<Blob | null>(null);
  const { description, onCloseModal, step, setStep, aspectRatio } =
    useImageInputContext();
  const { onSelect } = useImageInputContext();

  return (
    <div style={{ marginTop: 20 }}>
      {description && <Body1 style={{ marginBottom: 20 }}>{description}</Body1>}
      <div style={{ marginBottom: 5 }}>
        {step === "select" ? (
          <ImageDropzone
            onSelectImage={(img) => {
              setImage(img);
              setStep("crop");
            }}
            Wrapper={ImageWrapper}
          />
        ) : step === "crop" ? (
          <ImageCrop
            srcImg={image!}
            onCrop={(img) => setCroppedImg(img)}
            Wrapper={ImageWrapper}
          />
        ) : (
          <Uploading />
        )}
        {step === "crop" && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <ButtonsContainer>
              <>
                <StyledButton onClick={onCloseModal} text>
                  Cancel
                </StyledButton>
                <StyledButton
                  onClick={() => {
                    if (croppedImg) {
                      setStep("upload");
                      onSelect(croppedImg);
                    }
                  }}
                  disabled={!croppedImg}
                >
                  Save
                </StyledButton>
              </>
            </ButtonsContainer>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectImageModal;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  gap: 10px;

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
    flex-direction: column-reverse;
  }
`;

const StyledButton = styled(Button)`
  ${({ theme }) => theme.media.mobile} {
    width: 100%;
  }
`;
