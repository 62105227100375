import { UserProvider } from "auth";
import { AuthProvider } from "Components/AuthContext";
import { Theme, theme } from "./melodies-source/Theme";
import { Routes } from "Routes";
import { Toast } from "melodies-source/Toast";
import { ContactProvider } from "Components";

const t = {
  ...theme,
  media: {
    mobile: "@media all and (max-width: 768px)",
    tablet: "@media all and (min-width: 769px) and (max-width: 1263px)",
    maxTablet: "@media all and (max-width: 1263px)",
    desktop: "@media all and (min-width: 769px)",
  },
};

function App() {
  return (
    //@ts-ignore
    <UserProvider>
      <Theme theme={t}>
        <ContactProvider>
          <AuthProvider>
            <Routes />
            <Toast />
          </AuthProvider>
        </ContactProvider>
      </Theme>
    </UserProvider>
  );
}

export default App;
