import { useState } from "react";
import { styled } from "styled-components";
import { Layout } from "Components";
import { Button } from "melodies-source/Button";
import { TextInput } from "melodies-source/TextInput";
import { Body1, H4, Subtitle1 } from "melodies-source/Text";
import { ReactComponent as Logo } from "assets/svg/logo.svg";
import { ReactComponent as EmailIcon } from "assets/svg/email-outlined.svg";

export const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  return (
    <Layout flow="login">
      <Container>
        <StyledLogo />
        <H4>Forgot Password</H4>
        <Body1>
          Enter the email address associated with your SET account and we’ll
          email you instructions to reset your password.
        </Body1>
        <TextInput
          value={email}
          onChange={setEmail}
          label="Email"
          placeholder="Email..."
          leftIcon={<EmailIcon />}
        />
        <Buttons>
          <Button>Reset Password</Button>
          <Button text>Back</Button>
        </Buttons>
      </Container>
    </Layout>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;

  ${Body1} {
    text-align: center;
  }
`;

const StyledLogo = styled(Logo)`
  width: 150px;
  height: 66px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-top: 10px;

  button {
    width: 100%;
  }
`;
