import { Layout, useSignupContext } from "Components";
import { Button } from "melodies-source/Button";
import { Body1, H4 } from "melodies-source/Text";
import { ReactComponent as Logo } from "assets/svg/logo.svg";
import { styled } from "styled-components";

export const DuplicateArtist = () => {
  const { handleBack } = useSignupContext();
  return (
    <Layout
      flow="login"
      cardProps={{
        actions: {
          buttons: (
            <Button variant="secondary" onClick={() => handleBack()}>
              Back
            </Button>
          ),
        },
      }}
    >
      <Container>
        <Logo />
        <H4>Existing Artist</H4>
        <Body1>
          It appears this artist has already been added to your account. If you
          do not see this artist or have access, the artist account may still be
          pending verification.
        </Body1>
      </Container>
    </Layout>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  text-align: center;
  width: 100%;

  svg {
    width: 150px;
    height: 66px;
  }

  button {
    width: 100%;
  }
`;
