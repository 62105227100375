import React, { useState } from "react";
import { H1, H2 } from "melodies-source/Text";
import { Button } from "melodies-source/Button";
import { Modal } from "melodies-source/Modal";
import { TextInput } from "melodies-source/TextInput";
import { Select } from "melodies-source/Select";
import { Textarea } from "melodies-source/Textarea";
import styled from "styled-components";
import { useUser } from "auth";
import { addDoc, collection, getFirestore } from "firebase/firestore";
import { toast } from "react-hot-toast";
import { ReactComponent as EmailIcon } from "assets/svg/email-outlined.svg";

const REASONS = [
  "Trouble Logging in",
  "Can't Access Artist",
  "Report Artist",
  "Question about SET Features",
  "Inquiry about a Brand Partnership",
  "Interest in Using Tech for Non-Music",
];

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const ContactUs: React.FC<Props> = ({ open, setOpen }) => {
  const { user } = useUser();
  const name = user?.displayName?.split(" ");
  const [firstName, setFirstName] = useState(name?.[0] || "");
  const [lastName, setLastName] = useState(name?.[1] || "");
  const [email, setEmail] = useState(user?.email);
  const [reason, setReason] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const resetForm = () => {
    setFirstName(name?.[0] || "");
    setLastName(name?.[1] || "");
    setEmail(user?.email);
    setReason("");
    setMessage("");
  };

  const handleSubmit = async () => {
    setLoading(true);
    await addDoc(collection(getFirestore(), "contact_us"), {
      code: "signup",
      name: `${firstName} ${lastName}`,
      email,
      reason,
      message,
      uid: user?.uid,
    });
    toast.success("Message Sent");
    setOpen(false);
    resetForm();
    setLoading(false);
  };

  return (
    <Modal isOpen={open} withCloseIcon={false} onClose={() => setOpen(false)}>
      <Container>
        <H1>Contact Us</H1>
        <Form>
          <Name>
            <TextInput
              value={firstName}
              onChange={setFirstName}
              label="First Name"
              placeholder="First Name"
            />
            <TextInput
              value={lastName}
              onChange={setLastName}
              label="Last Name"
              placeholder="Last Name"
            />
          </Name>
          <TextInput
            value={email || ""}
            onChange={setEmail}
            label="Email Address"
            placeholder="Email"
            leftIcon={<EmailIcon />}
          />
          <Select
            value={reason}
            onChange={setReason}
            label="What best describes your reason for contacting us?"
            options={REASONS.map((reason) => ({
              value: reason,
              label: reason,
            }))}
          />
          <Textarea
            value={message}
            onChange={setMessage}
            label="Your Message"
          />
          <Buttons>
            <Button
              onClick={handleSubmit}
              loading={loading}
              disabled={
                !firstName || !lastName || !email || !reason || !message
              }
            >
              Submit
            </Button>
            <Button onClick={() => setOpen(false)} text>
              Cancel
            </Button>
          </Buttons>
        </Form>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  margin-top: 5px;

  ${H1}, ${H2} {
    text-align: center;
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
`;

const Name = styled.div`
  display: flex;
  gap: 20px;
  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;
